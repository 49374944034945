import { http } from "./http.js";

/**
 * @param {"get" | "post"} method
 * @returns {(url: string) => (fileName: string, param: {}) => void}
 */
let createDownload = method => url => (fileName, param) => {
  http[method](url)(param, { responseType: "blob" })
    .then(res => downloadBlob(res.data, fileName));
};

/**
 * @param {Blob} blob
 * @param {string} fileName
*/
export function downloadBlob(blob, fileName) {

  let aHref = URL.createObjectURL(blob);
  let a = document.createElement("a");
  a.target = "_blank";
  a.href = aHref;
  a.download = fileName || "";
  a.click();
  URL.revokeObjectURL(aHref);
}

export const download = Object.freeze({
  get: createDownload("get"),
  post: createDownload("post")
});
