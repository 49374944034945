<template>
  <div class="bg">
    <div class="box">
      <div class="content">
        <div class="logo">
          <img src="../images/mini-logo.png">
        </div>
        <div class="title">
          <div class="max-title">国家工业互联网标识解析体系(北京)顶级节点</div>
          <div class="min-title">First level node of national industrial Internet identification analysis system (Beijing)</div>
        </div>
        <div class="title">
          <div class="max-title">中国工业互联网标识服务中心</div>
          <div class="min-title">
            China Industrial Internet identification service center
          </div>
        </div>
        <div class="main-title">
          <img
            src="https://assets-service.obs.cn-south-1.myhuaweicloud.com/test/mp_642b1820-9e79-11ec-b125-0bb52bbb7840/da3a5e23-c9a1-413c-bbaa-411fa8755d68.png"
          >
        </div>
        <div class="desc">
          国家工业互联网标识解析体系企业识别码<span>{{
            info.company_node
          }}</span>已由<span>{{ info.company_registe_info.entBasicInfo.orgName }}</span>注册，并已在国家工业互联网标识解析体系顶级节点中备案。
        </div>
        <div class="flex company">
          <div class="left"><img :src="qrCode"></div>
          <div class="right">
            <div>
              企业名称：{{ info.company_registe_info.entBasicInfo.orgName }}
            </div>
            <div>企业标识码：{{ info.company_node }}</div>
            <div>注册日期：{{ registerTime() }}</div>
          </div>
          <img
            class="seal-img"
            src="https://assets-service.obs.cn-south-1.myhuaweicloud.com/test/mp_642b1820-9e79-11ec-b125-0bb52bbb7840/f30ab5d9-4aec-46c8-b202-a7a2274b3225.png"
          >
        </div>
        <div class="tip">注册服务机构：工业互联网标识解析国家顶级节点--北京</div>
        <div class="mall-tip">
          以下说明与本证书主文一起构成本证书统一整体，不可分割:
          1.本证书上列出的企业是所列企业识别码的合法持有人，依法享有该企业识别码项下之各项权利。2.本证书中所列企业识别码之注册或使用而可能引发的与任何第三方之纠纷或冲突，
          均由该企业识别码注册企业本人承担，企业识别码所属注册二级节点不承担任何法律责任。企业识别码所属注册二级节点亦不在此类纠纷或冲突中充当证人、调停人或其它形式之参与人。3.
          本证书不得用于非法目的，企业识别码所属注册二级节点不承担任何由此而发生或可能发生之法律责任。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { companyApi } from "@/services/api";
import config from "@/config/config";
import { formatDate } from "@/utils/time";
export default {
  data() {
    return {
      qrCode: "",
      info: {
        company_registe_info: {
          entBasicInfo: {}
        }
      }
    };
  },
  mounted() {
    this.getInfo();
    let _resizeWindow = function() {
      let $html = document.getElementsByTagName("html")[0];
      let offsetWidth = document.body.clientWidth;
      if (!offsetWidth) return;
      if (window.screen.availWidth < 1200) {
        document.documentElement.style.fontSize = `100px`;
      } else {
        document.documentElement.style.fontSize = `150px`;
      }
      document.body.style.minWidth = "auto";
    };
    _resizeWindow();
  },
  computed: {
    companyMpkey() {
      return this.$route.query.mpkey;
    }
  },
  methods: {
    async getInfo() {
      let { data } = await companyApi.getNodeInfo({
        node: this.$route.query.nodeId
      });
      this.info = data;
      this.getQrCode();
    },
    registerTime() {
      return formatDate(this.info.company_registe_time, "yyyy年MM月dd日");
    },
    async getQrCode() {
      console.log("this.info", this.info);
      let { data } = await companyApi.getQrCode({
        content: `${config.webSiteHttp}record?nodeId=${this.info.company_node}`
      });
      this.qrCode = data;
    }
  }
};
</script>

<style lang="less" scoped>
* {
  // box-sizing: border-box;
}
html,
body {
  width: 100%;
  // overflow-x: hidden;
}
body {
  line-height: 1;
  min-width: none;
}
html {
  overflow-y: scroll;
  // overflow-x: hidden;
}
.bg {
  background-color: #ffffff;
}
.flex {
  display: flex;
}
.box {
  background-size: cover !important;
  // height: 100vh;
  max-width: 744px !important;
  // overflow-x: hidden;
  width: 100%;
  margin: 0 auto;
  // padding: 0.24rem 0.17rem;
  background: #f3f9ff;
  .content {
    background: url("https://assets-service.obs.cn-south-1.myhuaweicloud.com/test/mp_642b1820-9e79-11ec-b125-0bb52bbb7840/6172da75-6fee-406d-a7d0-28a57617b2f8.png")
      no-repeat;
    background-size: 100% 100%;
    width: 100%;
    // height: 100%;
    // padding: 0.15rem 0.156rem;
    // padding-bottom: 0.4rem;
    .logo {
      text-align: center;
      img {
        width: 0.5rem;
        height: 0.5rem;
      }
    }
    .title {
      margin-top: 0.15rem;
      text-align: center;
      .max-title {
        color: #222222;
        font-size: 0.16rem;
        font-weight: bold;
      }
      .min-title {
        font-size: 0.12rem;
        color: #7b7e8c;
        width: 75%;
        margin: 0 auto;
      }
    }
    .main-title {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.2rem 0;
      // margin-top: 0.35rem;
      img {
        width: 3rem;
        height: 0.4rem;
      }
    }
    .desc {
      // height: 9.75rem;
      // margin-top: 3.5rem;
      color: #222222;
      font-size: 0.13rem;
      font-weight: 500;
      width: 75%;
      margin: 0 auto;
      margin-bottom: 0.23rem;
      line-height: 0.19rem;
      font-family: Source Han Sans SC;
      span {
        color: #1285d6;
      }
    }
    .company {
      color: #222222;
      font-size: 0.1rem;
      font-family: Source Han Sans SC;
      font-weight: 500;
      align-items: center;
      justify-content: center;
      position: relative;
      // width: 75%;
      // padding-left: 0.1rem;
      // margin: 0 auto;
      .left {
        width: 0.56rem;
        height: 0.56rem;
        // background: #1285d6;
        margin-right: 26px;
        img {
          width: 0.56rem;
          height: 0.56rem;
        }
      }
      .right {
        // height: 6.25rem;
        border-left: 2px solid #222222;
        padding-left: 0.2rem;
        line-height: 0.26rem;
        z-index: 1;
        div {
          // margin-bottom: 0.9rem;
        }
      }
    }
    .tip {
      font-size: 0.12rem;
      font-family: Source Han Sans SC;
      font-weight: 500;
      // line-height: 2.6rem;
      color: #222222;
      // margin-top: 1.56rem;
      line-height: 0.19rem;
      text-align: center;
      line-height: 0.5rem;
    }
  }
}
.seal-img {
  position: absolute;
  right: 0.8rem;
  width: 0.7rem;
  height: 0.7rem;
  z-index: 0;
}
.mall-tip {
  font-size: 0.09rem;
  color: #7b7e8c;
  font-weight: 400;
  // line-height: 1.125rem;
  font-family: Microsoft YaHei;
  margin-top: 0.1875rem;
  width: 75%;
  margin: 0 auto;
  line-height: 0.19rem;
  padding-bottom: 0.5rem;
}
</style>
