export function noop() {}

/**
 * 延时
 * @param {number} timespan
 */
export function delay(timespan) {
  return new Promise(r => setTimeout(() => r(), timespan));
}

export async function isValidImgUrl(url) {
  return await new Promise((resolve, reject) => {
    let img = document.createElement("img");
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
    img.src = url;
  });
}

/**
 * 保留两位小数字符串
 */
export function moneyFormat(val) {
  return (Number(val) || 0).toFixed(2);
}

/**
 * 读取文件
 * @param {File} file
 * @returns {Promise<string>}
 */
export function readAsDataUrl(file) {
  return new Promise((resolve, reject) => {
    let fr = new FileReader();
    fr.readAsDataURL(file);
    fr.onload = () => {
      resolve(fr.result);
    };
    fr.onerror = e => reject(e);
  });
}

/**
 * 读取文件
 * @param {File} file
 * @returns {Promise<string>}
 */
export function readAsText(file) {
  return new Promise((resolve, reject) => {
    let fr = new FileReader();
    fr.readAsText(file);
    fr.onload = () => {
      resolve(fr.result);
    };
    fr.onerror = e => reject(e);
  });
}

/**
 * @param {string} prefix
 * @param {any} obj
 * @example
 *
 * unprefixKeys("form_", { form_id: 1, form_value: 2, other: 3 })
 * // { id: 1, value: 2 }
 */
export function unprefixKeys(prefix, obj) {
  let ret = {};
  let keys = Object.keys(obj);
  for (let key of keys) {
    if (!key.startsWith(prefix)) continue;
    let trimed = key.substr(prefix.length);
    ret[trimed] = obj[key];
  }
  return ret;
}

/**
 * @param {string} prefix
 * @param {any} obj
 * @example
 *
 * prefixKeys("form_", { id: 1, other: 2 })
 * // { form_id: 1, form_other: 2 }
 */
export function prefixKeys(prefix, obj) {
  let ret = {};
  let keys = Object.keys(obj);
  for (let key of keys) {
    let prepend = prefix + key;
    ret[prepend] = obj[key];
  }
  return ret;
}

export { formatDate } from "./time.js";

export function stringifyQuery(query) {
  let arr = [];
  for (let key in query) {
    let value = query[key];
    if (value == null) continue;

    // 这里不能把 encodeURIComponent 去掉，否则 key 和 value 中包含特殊字符时拼的 url 无效
    // 在页面 onLoad 函数里取参数时需要经过一次 decodeURIComponent
    arr.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
  }
  return arr.join("&");
}
/**
 * 拼接 url query 参数
 * @param {string} url
 * @param {Record<string, string | number>} query
 */
export function concatQuery(url, query) {
  if (isEmptyObject(query)) return url;

  let queryString = stringifyQuery(query);

  if (!url.includes("?")) return `${url}?${queryString}`;

  if (url.endsWith("?") || url.endsWith("&")) return url + queryString;

  return `${url}&${queryString}`;
}
/** @type {(obj: any) => obj is BAT.Empty} */
export function isEmptyObject(obj) {
  if (obj.keys().length) return false;
  return true;
}

// 时间戳转日期时间格式
export function add0(m) {
  return m < 10 ? `0${m}` : m;
}

export function dateTime(timestamp) {
  return format(timestamp);
}

export function date(timestamp) {
  return format(timestamp, "Year");
}

export function dateTimeNoSeconds(timestamp) {
  return format(timestamp, "noSeconds");
}

export function format(shijianchuo, type) {
  if (!shijianchuo) return "";

  // shijianchuo是整数，否则要parseInt转换
  let T = parseInt(shijianchuo);
  let time = new Date(T);
  let y = time.getFullYear();
  let m = time.getMonth() + 1;
  let d = time.getDate();
  let h = time.getHours();
  let mm = time.getMinutes();
  let s = time.getSeconds();
  // 年月日
  if (type == "Year") {
    return `${y}-${add0(m)}-${add0(d)}`;
  } else if (type == "Hours") {
    // 时分秒
    return `${add0(h)}:${add0(mm)}:${add0(s)}`;
  } else if (type == "noSeconds") {
    // 2020年01月20日 16: 15
    return `${y}年${add0(m)}月${add0(d)}日 ${add0(h)}: ${add0(mm)}`;
  }
  return `${y}-${add0(m)}-${add0(d)} ${add0(h)}:${add0(mm)}:${add0(s)}`;
}

/** 号码中间隐藏 */
export function formatPhone(phone) {
  if (!phone) return "--";
  return String(phone).replace(/(^\d{3})(\d{4})(\d{4}$)/, "$1****$3");
}

/** 判断基础数据类型是否为空 */
export function isNull(str) {
  const isNull = ["", null].includes(str);
  return isNull ? "--" : str;
}

// 判断几个值是否都为真或者都为假
export function isAllFitTrueOrFalse(...args) {
  let bool;
  bool = args.every(item => Boolean(item));
  if (bool) return true;
  bool = args.every(item => !item);
  if (bool) return true;
  return false;
}


