import "./flat-shim";

import Vue from "vue";

import Element from "element-ui";
import VueClipboard from "vue-clipboard2";
import "element-ui/lib/theme-chalk/index.css";
import "./tinymce";

Vue.use(Element);
Vue.use(VueClipboard);

