<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import store from "./store";

export default {
  name: "App",
  components: {
  }
};
</script>

<style scoped>
@import "./style.css";

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html .el-table th{
  background-color: #fafafa;
}
/* 顶部导航栏固定 */
.el-container{
  display: -webkit-box !important
}
</style>
