/* eslint-disable new-cap */
import Axios from "axios";
import _ from "lodash";

import config from "../config/config";
// import mock from "../mock";

import { readAsText } from "@/utils/utils.js";
import { showError } from "../utils/error.js";
import store from "../store";

/**
 * axio 数据流
 *
 * 1. 调用
 * 2. `interceptors.request`
 * 3. `transformRequest`
 * 4. `adapter`
 * 5. `transformResponse`
 * 6. `interceptors.response`
 * 7. 返回结果
 */


let client = Axios.create({
  baseURL: config.apiHost,
  headers: {
    productCode: config.productCode
  },
  // adapter: process.env.NODE_ENV == "development" ? mock : null,
  validateStatus(status) {
    return status < 500;
  }
});
let arraybuffer = Axios.create({
  baseURL: config.apiHost,
  responseType: "blob",
  headers: {
    productCode: config.productCode
  },
  // adapter: process.env.NODE_ENV == "development" ? mock : null,
  validateStatus(status) {
    return status < 500;
  }
});

export let http = {
  /**
     * @template {keyof K.ApiList} url
     * @param {url} url
     * @returns {K.ApiList[url]}
     */
  get: url => {
    let requestFn = (params, config) => client.get(url, { params, ...config });
    requestFn.path = url;
    return requestFn;
  },

  /**
     * @template {keyof K.ApiList} url
     * @param {url} url
     * @returns {K.ApiList[url]}
     */
  post: url => {
    let requestFn = (data, config) => client.post(url, data, config);
    requestFn.path = url;
    return requestFn;
  },
  httpDelete: url => {
    let requestFn = (params, config) => client.delete(url, { params, ...config });
    requestFn.path = url;
    return requestFn;
  },
  put: url => {
    let requestFn = (data, config) => client.put(url, data, config);
    requestFn.path = url;
    return requestFn;
  },
  formPost: url => {
    let requestFn = (data, config) => {
      let formData = new FormData();
      for (let key in data) formData.append(key, data[key]);
      return client.post(url, formData, config);
    };
    requestFn.path = url;
    return requestFn;
  },
  getArraybuffer: url => {
    let requestFn = (params, config) => arraybuffer.get(url, { params, ...config });
    requestFn.path = url;
    return requestFn;
  }
};

/** @param {string} baseURL */
export function httpWithBase(baseURL) {
  return {
    /**
     * @template {keyof K.ApiList} url
     * @param {url} url
     * @returns {K.ApiList[url]}
     */
    get: url => (params, config) =>
      http.get(url)(params, { baseURL, ...config }),
    /**
     * @template {keyof K.ApiList} url
     * @param {url} url
     * @returns {K.ApiList[url]}
     */
    post: url => (data, config) =>
      http.post(url)(data, { baseURL, ...config }),
    /**
     * @template {keyof K.ApiList} url
     * @param {url} url
     * @returns {K.ApiList[url]}
     */
    httpDelete: url => (params, config) =>
      http.httpDelete(url)(params, { baseURL, ...config }),
    put: url => (data, config) =>
      http.put(url)(data, { baseURL, ...config }),
    formPost: url => (data, config) =>
      http.formPost(url)(data, { baseURL, ...config })
  };
}
arraybuffer.interceptors.request.use(req => {
  let ms_session = store.getters["ms_session"];
  let mpkey = store.state.meb_mpkey;

  if (!req.params) req.params = {};

  if (ms_session) {
    req.params.ms_session = ms_session;
    req.headers["ms_session"] = ms_session;
  }

  req.params.mpkey = mpkey;

  req.headers.common.mpkey = mpkey;

  return req;
});
client.interceptors.request.use(req => {
  let ms_session = store.getters["ms_session"];
  let mpkey = store.state.mpkey;

  if (!req.params) req.params = {};

  if (ms_session) {
    req.params.ms_session = ms_session;
    req.headers["ms_session"] = ms_session;
  }
  req.params.mpkey = mpkey;

  req.headers.common.mpkey = mpkey;

  return req;
});

client.interceptors.response.use(res => res);

function loginOut(res) {
  window.location.href = "/login";
}

client.interceptors.response.use(async res => {
  let isContentTypeJSON = res.headers["content-type"]
      && res.headers["content-type"].includes("application/json");

  // xhr responseType 设置为 blob 但服务端报错时会返回 json 字符串, 此时 js 中的 resBody 为 blob 类型，需要手动转换为 json 对象
  let isResponseTypeBlob = res.data instanceof Blob;

  if (isContentTypeJSON && isResponseTypeBlob) {
    // eslint-disable-next-line
    res.data = JSON.parse(await readAsText(res.data, "utf8"));
  }

  return res;
});

client.interceptors.response.use(res => {
  let isContentTypeJSON = res.headers["content-type"]
      && res.headers["content-type"].includes("application/json");
  if (!isContentTypeJSON) return res;

  // 清除midway缓存单独处理，接口没有返回code
  if (res.config.url.includes("template/cache/clear")) {
    if (res.status == 200) {
      return "清除成功";
    }
    showError("清除失败");

  }
  let state = res.status;

  let resBody = res.data;

  let code;
  let data;
  let message;
  let ms_session;
  try {
    code = resBody.code;
    data = resBody.data;
    ms_session = resBody.ms_session;
    message = resBody.message;

    if (code == 200 &&
                ms_session &&
                ms_session != "undefined" &&
                store.getters["ms_session"] != ms_session
    ) {
      store.dispatch("updateSession", ms_session);
    }
  } catch (e) {
    let err = new Error("无效的响应");
    err.res = res;
    err.inner = e;
    showError(err);
  }

  if (code == 10005 || code == 10001) {
    store.dispatch("logout");
    loginOut();
    let err = new Error("登录失效");
    err.res = res;
    err.code = res.data.code;
    showError(err);
    throw err;
  }
  if (res.data.code !== 200) {
    let err = new Error(message);
    err.res = res;
    err.code = res.data.code;
    showError(err);
    throw err;
  }

  return resBody;
});
