import axios from "axios";
import config from "../config/config";
import {
  download
} from "./download";
import {
  http
} from "./http";

const {
  get,
  post,
  put,
  formPost,
  httpDelete,
  getArraybuffer
} = http;
export const assetsApi = {
  captcha: data =>
    get("/captcha")(data, {
      baseURL: config.uploadImgHost,
      responseType: "blob"
    }),
  uploadImgBase64: post(`${config.uploadImgHost}/space_upload/base64`),
  assetsUploadImgBase64: post(`${config.uploadImgHost}/upload/base64`),
  uploadImg: formPost(`${config.uploadImgHost}/space_upload`),
  /** 上传小程序平台的校验文件 */
  uploadValidation: post(`${config.uploadImgHost}/upload/validation`)
};

export const identifierApi = {
  updateCategory: post("/category/save"),
  list: get("/category/findAll"),
  delete: post("/category/delete")

};

export const userInfoApi = {
  login: post("/login/customerLogin"),
  getCode: get("/login/sendLoginCode"),
  oauth: post("/login/oauth"),
  customerOauth: post("/login/customerOauth"), // 万云过来一键登录
  resetPassword: post("/member/resetPassword")
};

export const templateApi = {
  list: get("/template/list"),
  save: post("/template/save"),
  detail: get("/template/detail"),
  delete: post("/template/delete")
};

export const identityApi = {
  list: get("/identity/lists"),
  register: post("/identity/register"),
  detail: get("/identity/detail"),
  update: post("/identity/update"),
  goodsIdentity: post("/identity/synGoodsIdentity"), // 商品标识同步
  batchRegister: formPost("/identity/batchRegister"), // 注册
  getChainQrcode: post("/identity/getChainQrcode"),
  assetsDetail: get("/identity/assets/detail"), // 资产标识--详情
  assetsrRegister: post("/identity/assets/register"), // 资产标识--添加
  assetsrUpdate: post("/identity/assets/update") // 资产标识--更新
};

export const companyApi = {
  info: get("/company/query"),
  category: get("/dictionary/lists"),
  handlePlugin: get("/company/handlePlugin"),
  getQrCode: get("/common/getQrCode"),
  downloadImag: get("/draw/images/buildImg"),
  getNodeInfo: get(`${config.webSiteApiHost}/company/query`)
};

export const webSiteApi = {
  getUrlConfig: get("/shop/params/getUrlConfig"),
  messgeList: get("/comment/list"),
  getElementConfig: get("/domainApplication/getDomainsElementConfig"),
  setElementConfig: post("/domainApplication/setDomainsElementConfig"),
  getAll: get("/domainApplication/getAll"),
  setUrlConfig: post("/shop/params/setUrlConfig"),
  detail: get(`${config.webSiteApiHost}/identity/detail`),
  onLoad: post(`${config.webSiteApiHost}/buriedPoint/onLoad`),
  onunload: post(`${config.webSiteApiHost}/buriedPoint/onunload`),
  save: post(`${config.webSiteApiHost}/comment/save`)
};

export const loginOutApi = {
  logout: get("/login/logout")
};