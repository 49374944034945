export default Object.freeze([
  {
    name: "首页",
    key: "home",
    route: "/home",
    icon: "icon-a-zu21877"
  },
  {
    name: "入网证书",
    key: "certificate",
    route: "/netCertificate",
    icon: "icon-zhengshu5"
  },
  {
    name: "数字档案",
    key: "record",
    route: "/archive",
    icon: "icon-dangan"
  },
  {
    name: "身份标签",
    key: "identityTag",
    route: "/identityTag",
    icon: "icon-identity"
  },
  {
    name: "标识应用",
    key: "wabpage",
    route: "/wabpage",
    icon: "icon-a-zu21873",
    children: [
      {
        name: "网页赋码",
        key: "index",
        route: "/wabpage/site"
      }
    ]
  },
  {
    name: "标识管理",
    key: "manage",
    route: "/manage",
    icon: "icon-a-zu21273",
    children: [
      {
        name: "标识管理",
        key: "identityManage",
        route: "/manage/identity"
      },
      {
        name: "网页标识",
        key: "Webpage",
        route: "/manage/webpage"
      },
      {
        name: "商品标识",
        key: "Goods",
        route: "/manage/goods"
      },
      {
        name: "资产标识",
        key: "identification",
        route: "/manage/identification"
      },
      {
        name: "标识分类",
        key: "category",
        route: "/manage/category"
      },
      {
        name: "数据模板",
        key: "patternplate",
        route: "/manage/patternplate"
      }
    ]
  }
]);
