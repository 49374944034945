<template>
  <div>
    <div class="upload_con" v-if="!useold" :class="[isHide?'hide':'']">
      <el-upload list-type="picture-card" :on-preview="handlePictureCardPreview" action
                 :limit="limit" :file-list="fileList" :on-exceed="exceedHandel" :on-remove="removeImg"
                 :http-request="httpRequest" :disabled="nouse"
      >
        <i class="el-icon-plus" />
      </el-upload>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="">
      </el-dialog>
      <div v-if="isShowTip" class="el-upload__tip">
        <span v-if="tip">{{ tip }}</span>
        <span v-else>请上传图片不超过{{ size > 0 ? size : 2 }}M，仅支持jpg/png/jpeg/gif格式</span>
      </div>
      <div v-if="tipcontent" class="el-upload__tip">{{ tipcontent }}</div>
    </div>
    <div class="upload_con" v-if="useold">
      <el-upload class="avatar-uploader" list-type="picture-card" action
                 :http-request="httpRequest" :show-file-list="false" :disabled="nouse"
      >
        <i class="el-icon-plus" v-if="!imgSrc" />
        <img v-if="imgSrc" :src="imgSrc" class="avatar">
      </el-upload>
      <span v-if="imgSrc && isDelete" class="el-icon-delete remove" @click="removeImg" />
      <div v-if="isShowTip" class="el-upload__tip">
        <span v-if="tip">{{ tip }}</span>
        <span v-else>请上传图片不超过{{ size > 0 ? size : 2 }}M，仅支持jpg/png/jpeg/gif格式</span>
      </div>
      <div class="content-scan">
        <image-content-scan v-if="showScan&&scanUrl" ref="scanContent" :scan-action="true"
                            :url="scanUrl"
        />
      </div>
      <div v-if="tipcontent" class="el-upload__tip">{{ tipcontent }}</div>
    </div>
  </div>
</template>
<style scoped>
.el-icon-close-tip {
  display: none;
}
/*去除upload组件过渡效果*/
/deep/ .el-upload-list__item {
  transition: none !important;
}
.upload_con {
  position: relative;
  display: inline-block;
  transition: all 0s;
}
.upload_con > div:first-of-type {
  /* height: 90px; */
  margin-bottom: 6px;
}
.upload_st_add {
  display: inline-block;
  margin-left: 16px;
}
.upload_con >>> .el-upload--picture-card {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.avatar-uploader {
  display: inline-block;
}
/deep/.el-upload-list__item {
  width: 80px!important;
  height: 82px!important;
}
.upload_st_add {
  margin-left: 0;
}
.avatar {
  width: 100%;
  height: 100%;
  background-size: cover;
  object-fit: scale-down;
  background: #f5f5f5;
}
.upload_con >>> .el-upload--picture-card {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.upload_con.hide >>> .el-upload--picture-card {
  display: none;
}
.el-icon-plus {
  display: block;
  line-height: 80px;
}
.el-icon-delete {
  position: absolute;
  font-size: 20px;
  top: 28px;
  left: 95px;
}
.content-scan {
  position: absolute;
  font-size: 20px;
  bottom: -24px;
}
.el-upload__tip {
  margin-top: 6px;
  line-height: 20px;
  color: var(--text3-color);
}
</style>

<script>
import { assetsApi } from "@/services/api.js";
import { readAsDataUrl } from "@/utils/utils";
export default {
  model: {
    prop: "imgSrc",
    event: "change"
  },
  props: {
    limit: {
      type: Number,
      default: 1
    },
    useold: {
      type: Boolean,
      default: false
    },
    columnid: {
      type: Number,
      default: 0
    },
    imgSrc: {
      type: String,
      default: ""
    },
    mpkey: {
      type: String,
      default: ""
    },
    size: {
      type: Number,
      default: 2
    },
    isShowTip: {
      type: Boolean,
      default: true
    },
    tip: {
      type: String,
      default: ""
    },
    tipcontent: {
      type: String,
      default: ""
    },
    width: { // 最小宽度
      default: 0,
      type: Number
    },
    height: { // 最小高度
      default: 0,
      type: Number
    },
    isDelete: {
      type: Boolean,
      default: true
    },
    nouse: {
      type: Boolean,
      default: false
    },
    showScan: {
      type: Boolean,
      default: false
    },
    isIco: {
      type: Boolean,
      default: false
    },
    extLimit: {
      type: Boolean,
      default: false
    },
    isJpg: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    scanUrl() {
      return this.imgSrc;
    },
    isHide() {
      return this.fileList.length == this.limit;
    }
  },
  watch: {
    imgSrc: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.fileList = [{ url: newVal }];
        } else {
          this.removeImg();
        }
        this.imgUrl = newVal;
      },
      immediate: true
    },
    limit: {
      handler(newVal, oldVal) {
        const dom = document.querySelectorAll(".upload_con .el-upload--picture-card");

        [...dom].forEach(item => {
          // 由于导致bug，先注释，下周一来了再和金亦晨对一下  2021/12/24
          // item.style.display = "none";
        });
      },
      immediate: true
    }
  },
  data() {
    return {
      imgUrl: "",
      columnUpload: {},
      columnUploadArr: [],
      dialogVisible: false,
      dialogImageUrl: "",
      fileList: []
    };
  },
  methods: {
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    exceedHandel() {
      this.$message.warning(`最多上传${this.limit}个图片`);
    },
    async httpRequest(options) {
      /** @type {File} */
      let file = options.file;
      if (!await this.validate(file)) {
        this.fileList = [];
        return;
      }

      let base64Str = await readAsDataUrl(file);

      let { data } = await assetsApi.assetsUploadImgBase64({
        relation_id: this.columnid,
        mp_auth_key: this.mpkey,
        base64_files: [{ content: base64Str }]
      });

      if (this.showScan && this.scanUrl) this.$refs.scanContent.refresh();
      let fileUrl = data[0].file;
      this.columnUploadArr = [];
      this.columnUpload[options.file.uid] = fileUrl;
      this.columnUploadArr.push(fileUrl);
      this.imgUrl = fileUrl;
      this.$emit("imgUrl", this.columnUploadArr);
      this.$emit("change", this.imgUrl);
    },
    async validate(file) {

      // 校验是否是ico
      if (this.isIco) {
        if (!file.type.endsWith("icon")) {
          this.$message.error("仅可上传ico格式!");
          return false;
        }
        // 校验文件宽高大小
        return new Promise((resolve, reject) => {
          let widthList = [16, 32];
          let heightList = [16, 32];
          let _URL = window.URL || window.webkitURL;
          let image = new Image();
          image.src = _URL.createObjectURL(file);
          image.onload = function() {
            // let valid = image.width == width && image.height == height;
            let valid = widthList.includes(image.width)
              && heightList.includes(image.height)
              && image.width == image.height;
            valid ? resolve() : reject();
          };
        }).then(
          () => file,
          () => {
            this.$message.error(`上传图片尺寸不符合`);
            return Promise.reject();
          }
        );
      }

      // 校验图片类型
      // const isExtentionValid = /^image\/(jpe?g|png|gif)$/i.test(file.type) || this.isIco;
      // if (!isExtentionValid) {
      //   this.$message.error("上传图片只能是 JPG/PNG/JPEG/GIF 格式!");
      //   return false;
      // }

      // 校验图片类型
      let allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (this.extLimit) {
        allowedTypes.push("image/gif");
      }
      if (!allowedTypes.includes(file.type)) {
        const allowedFormats = this.isJpg ? "JPG/JPEG" : `JPG/JPEG/PNG${this.extLimit ? "/GIF" : ""}`;
        this.$message.error(`上传图片只能是 ${allowedFormats} 格式!`);
        return false;
      }

      // 校验图片字节大小
      const isSizeValid = Math.round(file.size / 1024 / 1024 * 100) / 100 <= this.size;
      if (!isSizeValid) {
        this.$message.error(`上传图片大小不能超过 ${this.size}MB!`);
        return false;
      }

      // 校验图片像素大小
      if (await !this.validatePixelSize(file)) {
        this.$message.error(`请上传尺寸不小于 ${this.width}*${this.height}的图片`);
        return false;
      }

      return true;
    },
    validatePixelSize(file) {
      return new Promise(resolve => {
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
          resolve(img.width < this.width || img.height < this.height);
          URL.revokeObjectURL(img.src);
        };
      });
    },
    removeImg(e) {
      const idx = this.fileList.findIndex(item => item.url);
      this.fileList.splice(idx, 1);
      this.columnUploadArr = [];
      this.$emit("imgUrl", this.columnUploadArr);
      this.imgUrl = "";
      this.$emit("change", "");
      if (this.showScan && this.scanUrl) this.$refs.scanContent.refresh();
    }
  }
};
</script>

