/* eslint-disable no-undefined */
/**
 * flat flatMap 方法需要 babel@^7.4 以上（core-js@3.0）来支持
 * 直接升级包会导致兼容性问题。暂时手动实现
 */


addMethodArrayFlat();
addMethodArrayFlatMap();

function addMethodArrayFlat() {
  let flat = Array.prototype.flat;
  if (flat) return;

  flat = function(depthArg) {
    let O = toObject(this);
    let sourceLen = toLength(O.length);
    let A = arraySpeciesCreate(O, 0);
    A.length = flattenIntoArray(A, O, O, sourceLen, 0, typeof depthArg === "undefined" ? 1 : toInteger(depthArg));
    return A;
  };

  flat.toString = "flat() { [native code] }";

  // eslint-disable-next-line no-extend-native
  Object.defineProperty(Array.prototype, "flat", {
    enumerable: false,
    configurable: true,
    writable: true,
    value: flat
  });
}

function addMethodArrayFlatMap() {
  let flatMap = Array.prototype.flatMap;
  if (flatMap) return;

  flatMap = function flatMap(callbackfn, arg /* , thisArg */) {
    let O = toObject(this);
    let sourceLen = toLength(O.length);
    let A;
    aFunction(callbackfn);
    A = arraySpeciesCreate(O, 0);
    A.length = flattenIntoArray(A, O, O, sourceLen, 0, 1, callbackfn, arg);
    return A;
  };

  flatMap.toString = "flatMap() { [native code] }";
  // eslint-disable-next-line no-extend-native
  Object.defineProperty(Array.prototype, "flatMap", {
    enumerable: false,
    configurable: true,
    writable: true,
    value: flatMap
  });
}

// internal functions
// # https://github.com/zloirock/core-js/

function classof(it) {
  return {}.toString.call(it).slice(8, -1);
}

let isArray = Array.isArray || function isArray(arg) {
  return classof(arg) == "Array";
};

function toObject(argument) {
  return Object(requireObjectCoercible(argument));
}

function requireObjectCoercible(it) {
  if (typeof it === "undefined") throw new TypeError(`Can't call method on ${it}`);
  return it;
}

function toInteger(argument) {
  return isNaN(argument = Number(argument)) ? 0 : (argument > 0 ? Math.floor : Math.ceil)(argument);
}

function toLength(argument) {
  return argument > 0 ? Math.min(toInteger(argument), 0x1FFFFFFFFFFFFF) : 0; // 2 ** 53 - 1 == 9007199254740991
}

// `FlattenIntoArray` abstract operation
// https://tc39.github.io/proposal-flatMap/#sec-FlattenIntoArray
function flattenIntoArray(target, original, source, sourceLen, start, depth, mapper, thisArg) {
  let targetIndex = start;
  let sourceIndex = 0;
  let mapFn = mapper ? bind(mapper, thisArg, 3) : false;
  let element;

  while (sourceIndex < sourceLen) {
    if (sourceIndex in source) {
      element = mapFn ? mapFn(source[sourceIndex], sourceIndex, original) : source[sourceIndex];

      if (depth > 0 && isArray(element)) {
        targetIndex = flattenIntoArray(target, original, element, toLength(element.length), targetIndex, depth - 1) - 1;
      } else {
        if (targetIndex >= 0x1FFFFFFFFFFFFF) throw new TypeError("Exceed the acceptable array length");
        target[targetIndex] = element;
      }

      targetIndex++;
    }
    sourceIndex++;
  }
  return targetIndex;
}

function bind(fn, that, length) {
  aFunction(fn);
  if (typeof that === "undefined") return fn;
  switch (length) {
  case 0: return function() {
    return fn.call(that);
  };
  case 1: return function(a) {
    return fn.call(that, a);
  };
  case 2: return function(a, b) {
    return fn.call(that, a, b);
  };
  case 3: return function(a, b, c) {
    return fn.call(that, a, b, c);
  };
  }
  return function(...args) {
    return fn.apply(that, args);
  };
}

function aFunction(it) {
  if (typeof it != "function") {
    throw new TypeError(`${String(it)} is not a function`);
  } return it;
}

function arraySpeciesCreate(originalArray, length) {
  let C;
  if (isArray(originalArray)) {
    C = originalArray.constructor;
    // cross-realm fallback
    if (typeof C == "function" && (C === Array || isArray(C.prototype))) C = undefined;
    else if (isObject(C)) {
      /** @see https://github.com/zloirock/core-js/blob/master/packages/core-js/internals/array-species-create.js */
      throw new Error("not implement yet!");
      // C = C[SPECIES];
      // if (C === null) C = undefined;
    }
  }
  return new (typeof C === "undefined" ? Array : C)(length === 0 ? 0 : length);
}

function isObject(it) {
  return typeof it === "object" ? it !== null : typeof it === "function";
}
