// 门店电话  支持座机、400电话和手机号三种输入格式
export const tel = /^((400-\d{3,4}-\d{3,4})|(0\d{2,3}-\d{7,8})|(1\d{10})?)$/;
export const url = /^((ht|f)tps?):\/\/[\w\-]+(\.[\w\-]+)+([\w\-\.,@?^=%&:\/~\+#]*[\w\-\@?^=%&\/~\+#])?$/;
export const phone = /^((1[3,5,8,7,9][0-9])|(14[5,7])|(17[0,6,7,8])|(19[1,7]))\d{8}$/;

export default {
  tel,
  url,
  phone
};
