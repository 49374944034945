<template>
  <nav>
    <el-menu :default-active="path" :router="true" unique-opened>
      <div
        v-for="(menu, index) in config"
        :key="menu.name"
        :index="String(index)"
      >
        <el-menu-item
          :index="menu.route"
          :key="menu.route"
          :route="menu.route"
          v-if="!menu.children"
        >
          <i class="iconfont single" style="color: #000" :class="menu.icon" />
          <span slot="title">{{ menu.name }}</span>
        </el-menu-item>
        <el-submenu :index="String(index)" v-else>
          <template slot="title">
            <i class="iconfont" style="color: #000" :class="menu.icon" />
            <span>{{ menu.name }}</span>
          </template>
          <!-- <el-menu-item-group> -->
          <el-menu-item
            v-for="menu2 in menu.children"
            :index="menu2.route"
            :route="menu2.route"
            :key="menu2.route"
          >
            {{ menu2.name }}
          </el-menu-item>
          <!-- </el-menu-item-group> -->
        </el-submenu>
      </div>
    </el-menu>
  </nav>
</template>

<script>
import _ from "lodash";
export default {
  props: {
    config: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      path: null,
      active: `${this.$route.path}`
    };
  },
  watch: {
    $route(to, from) {
      this.updateMenu(to, from);
    },
    active() {
      this.updateMenu(this.$route);
    },
    config() {
      this.updateMenu(this.$route);
    }
  },
  created() {
    this.updateMenu(this.$route);
  },
  methods: {
    updateMenu(route, fromRoute) {
      this.menuLevel1 = this.$props.config.find(menu =>
        route.path.startsWith(menu.route)
      );
      console.log(this.menuLevel1);
      if (this.menuLevel1 && this.menuLevel1.children) {
        // 当只有二级菜单的时，返回菜单本身
        let menuLevel3s = _.flatMap(this.menuLevel1.children, menuLevel2 =>
          menuLevel2.children ? menuLevel2.children : menuLevel2
        );
        let activeMenuLevel3 = menuLevel3s.find(
          m3 => m3 && route.path.startsWith(m3.route)
        );
        this.path = activeMenuLevel3 ? activeMenuLevel3.route : "";
      } else {
        this.path = this.menuLevel1.route;
        console.log(this.path);
      }
    }
  }
};
</script>

<style scoped lang="less">
.el-menu {
  white-space: nowrap;
  border-right: none;
  background-color: transparent !important;
}
.el-menu.el-menu--horizontal {
  border-bottom: none;
}

.el-menu-item {
  position: relative;
  height: 48px;
  display: flex;
  align-items: center;
  color: #011539;
  font-size: 14px;
  border-radius: 2px;
  &:hover {
    background-color: var(--main-menu-bacground-hover) !important;
  }
}

/deep/.el-submenu .el-menu-item {
  padding: 0;
}

.el-menu-item .iconfont {
  font-size: 16px;
  margin-right: 8px;
  line-height: 1;
}

.el-menu-item.is-active {
  /* element ui 会将计算出的颜色通过 style 的方式设置到元素上，这里必须要设置 important 才能覆盖 */
  color: #fff !important;
  background-color: var(--main-menu-bacground-hover) !important;
  .single {
    color: #fff !important;
  }
}

.el-menu-item::before {
  content: none;
  display: none;
}

.iconfont.iconmanage_fill {
  background: linear-gradient(var(--theme-red), var(--theme-red)) 0 0,
    linear-gradient(var(--theme-green), var(--theme-green)) 100% 0,
    linear-gradient(var(--theme-yellow), var(--theme-yellow)) 0 100%,
    linear-gradient(var(--theme-blue), var(--theme-blue)) 100% 100%;
  background-size: 50% 50%;
  background-repeat: no-repeat;
  background-clip: text;
  color: transparent;
}
.iconfont {
  margin-right: 8px;
}
</style>
