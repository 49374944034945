import App from "./App.vue";
import router from "./router";
import store from "./store";

import Vue from "vue";
import pagination from "@/components/common/pagination.vue";
import UploadImg from "@/components/common/UploadImg.vue";

import { codemirror } from "vue-codemirror";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/xq-light.css";

// 全局注册vue一键复制内容到粘贴板
import Clipboard from "clipboard";
Vue.prototype.Clipboard = Clipboard;

Vue.use(codemirror);


import "./utils/error";
import "./filters";
import "./plugins";

import routerPath from "vue-router";

const routerPush = routerPath.prototype.push;
routerPath.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error);
};


// 注册全局分页组件
Vue.component("pagination", pagination);
// 上传图片组件
Vue.component("UploadImg", UploadImg);

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

