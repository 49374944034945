import store from "@/store";
import managementMenuConfig from "@/config/management-menus.config";

/**
 * 鉴权重定向
 * 用户点击一级菜单时，会找到该用户有权限的第一个三级菜单并重定向过去
 * @param {import("vue-router").Route} to
 * @todo 优化此方法，与 topFrame 中筛选菜单的逻辑合并
 */
export function authRedirect(to) {
  let menuConfig = [].concat(managementMenuConfig);
  // 如果没有三级菜单，则到第二级结束
  let menus = menuConfig.find(menu => menu.route == to.path).children.flatMap(c => c.children);
  if (typeof menus[0] == "undefined") {
    menus = menuConfig.find(menu => menu.route == to.path).children;
  }
  try {
    return menus.find(menu => !menu.auth || store.state.user.funcKeys.includes(menu.auth)).route;
  } catch (e) {
    return "/login";
  }
}

/**
 * 判断某一个路由是否有权限
 * @param {import("vue-router").Route} route
 * 判断每层路由 在 management-menu.config 中有无对应菜单，再判断该菜单有无权限
 */
export function hasAuth(route) {
  let menuConfig = [].concat(managementMenuConfig);
  /** 所有的 3 级菜单 */
  let menus = menuConfig
    .flatMap(c => c.children ? c.children : c) // lv2
    .flatMap(c => c.children ? c.children : c); // lv3
  if (route.path === "/")
    return true;
  let hasMatchedMenu = false;
  for (let path of eachLevelPath(route.path)) {
    let menu = menus.find(m => m.route === path);
    if (!menu) continue;
    hasMatchedMenu = true;
    // 这里的 menu 都是三级菜单，三级菜单中没有 auth 字段的就表示不限制此三级菜单的访问
    if (!menu.auth) {
      return true;
    }
    // 如果三级菜单中有权限要求，就需要检查用户是否满足要求
    if (menu.auth && store.state.user.funcKeys.includes(menu.auth)) {
      return true;
    }
  }
  if (!hasMatchedMenu) return true;
  return false;
}

/**
 * @param {string} path
 */
function* eachLevelPath(path) {
  let pathSegements = path.split("/");
  let partialPath = "";
  for (let segement of pathSegements) {
    if (!segement) continue;
    yield partialPath += `/${segement}`;
  }
}
