import { authRedirect } from "./helper";

const category = () => import("@/views/manage/category.vue");
const patternplate = () => import("@/views/manage/patternplate.vue");
const IdentityManage = () => import("@/views/manage/identityManage.vue");
const addPatternplate = () => import("@/views/manage/add.vue");
const BulkRegister = () => import("@/views/manage/bulkregister");
const Goods = () => import("@/views/manage/goods");
const identification = () => import("@/views/manage/asset-identification/list.vue");
const identificationEdit = () => import("@/views/manage/asset-identification/edit.vue");
const Webpage = () => import("@/views/manage/webpage.vue");

/** @type {import("vue-router").RouteConfig[]} */
let routes = [
  {
    path: "/manage",
    redirect: authRedirect,
    meta: { pageTitle: "标识管理" }
  },
  {
    path: "/manage/identity",
    component: IdentityManage,
    meta: { pageTitle: "标识管理" }
  },
  {
    path: "/manage/category",
    component: category,
    meta: { pageTitle: "标识分类" }
  },
  {
    path: "/manage/patternplate",
    component: patternplate,
    meta: { pageTitle: "数据模板管理" }
  },
  {
    path: "/manage/patternplate/add",
    component: addPatternplate,
    meta: { pageTitle: "创建数据模板" }
  },
  {
    path: "/manage/webpage",
    component: Webpage,
    meta: { pageTitle: "网页标识" }
  },
  {
    path: "/manage/goods",
    component: Goods,
    meta: { pageTitle: "商品标识" }
  },
  {
    path: "/manage/identification",
    component: () => import("@/views/c-router-view.vue"),
    children: [
      {
        path: "",
        component: identification,
        meta: { pageTitle: "资产标识" }
      },
      {
        path: "new",
        component: identificationEdit,
        meta: { pageTitle: "添加" }
      },
      {
        path: "edit/:id",
        component: identificationEdit,
        meta: { pageTitle: "编辑" }
      }
    ]
  },
  {
    path: "/manage/webpage",
    component: Webpage,
    meta: { pageTitle: "网页标识" }
  },
  {
    path: "/manage/identity/bulkregister",
    component: BulkRegister,
    meta: { pageTitle: "批量注册标识" }
  }
];

export default routes;
