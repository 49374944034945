import { authRedirect } from "./helper";

const record = () => import("@/views/material/record.vue");
const certificate = () => import("@/views/material/certificate.vue");
const identityTag = () => import("@/views/material/identityTag.vue");


/** @type {import("vue-router").RouteConfig[]} */
let routes = [
  {
    path: "/material",
    redirect: authRedirect,
    meta: { pageTitle: "品牌素材" }
  },
  {
    path: "/archive",
    component: record,
    meta: { pageTitle: "数字档案" }
  },
  {
    path: "/netCertificate",
    component: certificate,
    meta: { pageTitle: "入网证书" }
  },
  {
    path: "/identityTag",
    component: identityTag,
    meta: { pageTitle: "身份标签" }
  }
];

export default routes;