<template>
  <el-pagination
    background
    @size-change="$emit('sizechange',$event);$emit('update:pageSize',$event);"
    @current-change="$emit('currentchange',$event);$emit('update:pageIndex',$event);"
    :current-page="pageIndex"
    :page-size="pageSize"
    :total="total"
    :layout="layout"
    :page-sizes="pageSizes"
  />
</template>
<script>
export default {
  name: "Pagination",
  props: {
    pageIndex: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 10
    },
    total: {
      type: Number,
      default: 0
    },
    layout: {
      type: String,
      default: "->, total, prev, pager, next, sizes, jumper"
    },
    pageSizes: {
      type: Array,
      default: () => [10, 20, 50, 100, 200]
    }
  }
};
</script>
<style scoped>
/deep/.el-pagination__sizes .el-select{
  width: auto !important;
}
</style>
