// export default
import Vue from "vue";

import { Message } from "element-ui";
import _ from "lodash";

export function showError(error) {
  if (isCancel(error)) {
    return;
  }

  if (isThirdPart(error)) {
    return;
  }

  if (error === null) return;

  if (typeof (error) === "undefined" || error._isShown) return;
  error._isShown = true;

  if (error && error.message)
    Message.error(error.message || "未知错误");
  console.dir(error);
}

if (process.env.NODE_ENV !== "test") {
  Vue.config.errorHandler = function(err, vm, info) {
    showError(err);

    console.group("Vue error");
    console.dir(err);
    console.dir(vm);
    console.dir(info);
    console.groupEnd("Vue error");
  };
}

window.addEventListener("error", errInfo => {
  let error = errInfo.error;
  showError(error);
});

window.addEventListener("unhandledrejection", errInfo => {
  let reason = errInfo.reason;
  showError(reason);
});

function isCancel(err) {
  let msg = "";

  if (typeof err == "object" && err) {
    msg = err.message;
  } else if (typeof err == "string") {
    msg = err;
  } else {
    return false;
  }

  if (typeof msg == "string" && msg.startsWith("cancel")) {
    return true;
  }
  return false;

}

function isThirdPart(err) {
  // firefox 在正式环境会报此错误，可能是因为 table 宽度变化引起，不是业务逻辑引起的
  if (err && err.message === "e is null") {
    return true;
  }

  return false;
}
