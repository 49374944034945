<template>
  <el-container class="top-frame">
    <el-header class="top-frame__header">
      <div class="right">
        <img class="logo-img" src="../images/mini-logo.png" alt="">
        <div class="header_title_box">
          <span class="title">工网通应用管理系统</span>
          <span class="sub_title">工业互联网标识应用中台</span>
        </div>
      </div>
      <div class="company-info">
        <span><i class="iconfont icon-renzheng" />{{ companyName }}</span>
        <span><i class="iconfont icon-morentouxiang" />{{ username }}</span>
        <button plain class="logout" @click="logout">退出</button>
      </div>
    </el-header>
    <el-container>
      <el-aside class="top-frame__main-menu" width="228px">
        <management-main-menu class="top-menu" :config="menuConfig" />
      </el-aside>
      <el-container>
        <el-header class="breadcrumb_header">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item
              v-for="(route, index) in breadcrumbs"
              :key="route.path"
              :to="index == breadcrumbs.length - 1 ? null : { path: route.path }"
            >
              {{ route.meta.pageTitle }}
            </el-breadcrumb-item>
            <!-- <div class="title" v-else>{{ title }}</div> -->
          </el-breadcrumb>
        </el-header>
        <el-main class="top-frame__main-content">
          <keep-alive>
            <router-view v-if="$route.meta.keepAlive" />
          </keep-alive>
          <router-view v-if="!$route.meta.keepAlive" />
        </el-main>
      </el-container>
    </el-container>
  </el-container>
</template>

<script>
import ManagementMainMenu from "../components/managementMainMenu.vue";
import ManagementMenuConfig from "../config/management-menus.config.js";
import _ from "lodash";

import { loginOutApi } from "@/services/api";

import { getTreePath } from "@/utils/tree";

export default {
  components: {
    ManagementMainMenu
  },
  computed: {
    companyLogo() {
      return this.$store.getters["companyInfo/siteHeaderLogo"];
    },
    companyName() {
      return this.$store.state.user.company_name;
    },
    username() {
      return this.$store.state.user.meb_phone;
    },
    /**
     * currentRoute = "/A/B/C"
     * [
     *   path: "/A/B/C" => matchRoute => pageTitle: "C"
     *   path  "/A/B/" => matchRoute => void
     *   path: "/A/" => matchRoute => pageTitle: "A"
     *   path: "/"  => matchRoute => void
     * ]
     * 面包屑 => "A/C"
     *
     * @returns {import("vue-router").Route[]}
     */
    breadcrumbs() {
      const result = [];
      let currentRoute = this.$route;

      while (currentRoute && result.every(route => route.path != currentRoute.path)) {
        result.unshift(currentRoute);
        currentRoute = this.getParentPathRoute(currentRoute);
      }
      return result.filter(route => route.meta.pageTitle);
    }
  },
  data() {
    return {
      menuConfig: []
    };
  },
  created() {
    this.getMenus();
    console.log(this.breadcrumbs);
  },
  watch: {},
  methods: {
    getMenus() {
      this.menuConfig = _.cloneDeep(ManagementMenuConfig);
      console.log("this.menuConfig", this.menuConfig);
    },
    /** @param {Route} route */
    getParentPathRoute(route) {
      /** @type {string} */
      let path = route.path;
      let getParentPath = path => path.split("/").slice(0, -1).join("/");

      let resultRoute;
      do {
        path = getParentPath(path);
        if (!path) return null;
        resultRoute = this.$router.match(path);
      } while (path != resultRoute.path); // is not redirected


      return resultRoute;
    },
    handleDropdown(command) {
      this[command]();
    },
    async logout() {
      await loginOutApi.logout();
      this.$store.dispatch("logout");
      this.$router.push("/login");
    }
  }
};
</script>

<style scoped lang="less">
.top-frame {
  height: 100vh;
}
.top-frame__main-menu {
  background-color: #fff;
  color: #011539;
  padding: 16px;
}
.top-frame__header {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e4e4e4;
}
.breadcrumb_header {
  height: 50px;
  display: flex;
  align-items: center;
  background-color: #fafafa;
  justify-content: space-between;
  padding-left: 16px !important;
}
.top-frame__header .left {
  width: 193px;
  height: 42px;
  border-right: 1px solid #e4e4e4;
  line-height: 60px;
}
.top-frame__header .left .name {
  padding-left: 13px;
  color: #011539;
  font-size: 14px;
}

.logo {
  height: 66px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo__clip {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  overflow: hidden;
  background: #fff;
}
.logo__image {
  width: 100%;
  height: 100%;
}

.top-menu {
  flex: 1;
  padding: 12px 0;
}
.el-header {
  position: relative;
  z-index: 1;
  // box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  padding: 0;
}

.top-frame__sub-menu-box {
  height: calc(100vh - 64px);
  border-right: 1px solid #e4e4e4;
  overflow: hidden;
}
.top-frame__main-content {
  position: relative;
  background: var(--page-background);
  padding: 16px;
  height: calc(100vh - 64px);
  min-width: 1200px;
}

.right {
  display: flex;
}
.logo-img {
  width: 42px;
  height: 42px;
  margin: 0 7px;
}
.right .title {
  height: 21px;
  font-size: 16px;
  font-weight: bold;
  color: #222222;
  margin-bottom: 2px;
}
.right .sub_title {
  height: 10px;
  font-size: 8px;
  font-weight: 400;
  color: #999999;
}
.right .header_title_box {
  display: flex;
  flex-direction: column;
}
.company-info {
  display: flex;
  padding-right: 19px;
  font-size: 12px;
  color: #011539;
  span {
    display: flex;
    align-items: center;
    margin-right: 31px;
  }
  span:first-child{
    margin-right: 36px;
  }
  span:last-child {
    align-items: center;
    margin-right: 0;
  }
  .iconfont {
    font-size: 18px;
    margin-right: 3px;
    vertical-align: bottom;
  }
  .icon-renzheng {
    color: #7b7e8c;
  }
  .icon-morentouxiang {
    color: #bfe2fb;
    margin-right: 7px;
  }
  .logout {
   width: 56px;
    height: 24px;
    border: 1px solid #1285D6;
    opacity: 1;
    border-radius: 2px;
    font-size: 12px;
    color: #1285D6;
    background: #ffffff;
    line-height: 20px;
    cursor: pointer;
  }
}
</style>
