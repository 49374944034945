<template>
  <div class="contaienr">
    <div class="title">{{ info.identity_name }}</div>
    <img
      v-if="info.assets_value_type == 0"
      class="assets_image_url"
      :src="info.assets_image_url"
      alt=""
    />
    <div v-else class="assets_advanced" v-html="info.assets_advanced" />
    <div class="footer_authentication">
      <img
        src="https://resource.iwanshang.cloud/test/mp_4cb2c0b0-c6ca-11ed-bfad-355e78a65777/9f768a0b-f2ce-4453-b14f-645c31eacbad.png"
        alt=""
      />
      <div class="text">本信息已加入国家工业互联网标识解析体系，不可假冒伪造</div>
    </div>
    <!--工网通标识-->
    <div class="blockchain" v-if="info.gwtUrl" @click="openGwtUrl()" />
  </div>
</template>

<script>
import { identityApi } from "@/services/api";
export default {
  data() {
    return {
      antDialogVisible: false,
      info: {},
    };
  },
  created() {
    this.getData();
    document.title = "资产标识";
    // 基准大小
    const baseSize = 37.5;
    // 设置 rem 函数
    function setRem() {
      const salepro = document.documentElement.clientWidth / 750;
      // 当前页面宽度相对于 750 宽的缩放比例，可根据自己需要修改.
      // 设置页面根节点字体大小
      document.documentElement.style.fontSize = `${baseSize * Math.min(salepro, 2)}px`;
      const app = document.getElementById("app");
      app.style.minWidth = "100%";
    }
    // 初始化
    setRem();
  },
  methods: {
    getData() {
      identityApi
        .assetsDetail({
          identity_id: this.$route.query.identity_id,
          identity_mpkey: this.$route.query.mpkey,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.info = res.data;
          }
        });
    },
    openGwtUrl() {
      window.open(this.info.gwtUrl);
    },
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: normal;
  // vertical-align: baseline;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  word-break: break-all;
  box-sizing: border-box;
}

html {
  overflow-y: scroll;
  overflow-x: hidden;
}
.contaienr {
  height: 100vh;
  font-size: 0.6rem;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0.7rem;
  position: relative;
  padding-bottom: 2.7rem;

  .title {
    font-size: 1rem;
    color: #333333;
    margin-bottom: 0.8rem;
  }

  .assets_image_url {
    border-radius: 0.5rem;
    box-shadow: 0px 0.1rem 0.5rem 0px rgba(0, 0, 0, 0.08);
    width: 100%;
  }

  .assets_advanced {
    /deep/ img {
      max-width: 100%;
    }
  }

  .footer_authentication {
    height: 2.7rem;
    background: rgb(0, 0, 0);
    color: #ffff;
    font-size: 0.5rem;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    padding-top: 0.5rem;

    img {
      width: 0.7rem;
      height: 0.7rem;
      margin-right: 0.3rem;
    }

    .text {
      width: 8.9rem;
      text-align: center;
    }
  }
}

.blockchain {
  position: fixed;
  right: -0.4rem;
  width: 3rem;
  height: 4rem;
  top: 30%;
  background: url("https://resource.iwanshang.cloud/test/mp_4cb2c0b0-c6ca-11ed-bfad-355e78a65777/d9a8d7e4-e4b0-4159-ba12-fd28488a7f7a.png")
    no-repeat;
  background-size: contain;
  z-index: 10;
}

.ant_img_box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ant_img_box .top_ant {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5867rem;
}

.ant_img_box .top_ant img {
  margin-right: 5px;
}

.ant_img_box .text_desc {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.5867rem;
  margin-bottom: 1.6rem;
  color: #4e4c4c;
  width: 66%;
  text-align: center;
}

.ant_img_box .qrcode_img_box {
  width: 6.9333rem;
  height: 6.9333rem;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-bottom: 6rem;
  background: url("https://assets-service.obs.cn-south-1.myhuaweicloud.com/test/mp_82382310-c251-11ed-a857-e14019a61c1e/8d4b5768-fbf9-4409-bc7a-49ac1461309d.png");
  background-size: cover;
}

.ant_img_box .qrcode_img_box .qrcode_img {
  width: 6.24rem;
  height: 6.24rem;
  text-align: center;
}

.copy-link {
  font-size: 0.6933rem;
  color: #4464eb;
  margin-top: 1.44rem;
  line-height: 0.7467rem;
}

.ant_img_box .qrcode_desc {
  color: #a5a5a5;
  margin-top: 0.72rem;
  font-size: 0.5867rem;
  margin-bottom: 1.4375rem;
}

.ant_img_box .close_btn {
  width: 3.2rem;
  height: 1.5rem;
  background: #3c65f5;
  border-radius: 0.1067rem;
  font-size: 0.48rem;
  color: #fff;
  text-align: center;
  line-height: 1.56rem;
  cursor: pointer;
}

.ant_img_box .top_ant div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.ant_img_box img {
  width: 1.3333rem;
  height: 1.3333rem;
}

.ant_img_box .title {
  font-size: 0.9333rem;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #303133;
}

.ant_img_box .desc {
  font-size: 0.5867rem;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #303133;
}

.ant_img_box .border_qrcode {
  border-bottom: 2px solid #fafafa;
  height: 1px;
  width: 80%;
}

@media screen and (min-width: 750px) {
  .contaienr {
    width: 375px;
    margin: 0 auto;
  }
}
</style>
