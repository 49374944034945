import Router from "vue-router";
import Vue from "vue";
import _ from "lodash";
import qs from "qs";

import store from "../store";

// 这是比较基础的页面，不用懒加载
import Login from "@/views/Login.vue";
import NotFound from "@/views/NotFound.vue";
import TopFrame from "@/views/topFrame.vue";
import shutDown from "@/views/home/shutDown.vue";
import Record from "@/views/record.vue";
import Idfile from "@/views/idfile.vue";
import Assets from "@/views/assets.vue";
import certificate from "@/views/certificate.vue";
import Home from "@/views/index.vue";
import { Message } from "element-ui";

import manageRoutes from "./manage";
import materialRoutes from "./material";
import wabpageRoutes from "./wabpage";

Vue.use(Router);
let router = new Router({
  routes: [
    {
      path: "/login",
      name: "login",
      component: Login
    },
    {
      path: "/shutDown",
      name: "shutDown",
      component: shutDown
    },
    {
      // eslint-disable-next-line max-len
      path: "/(home)/**/*",
      name: "topFrame",
      component: TopFrame,
      children: [
        {
          path: "/home",
          name: "home",
          meta: { pageTitle: "首页" },
          component: Home
        },
        ...manageRoutes,
        ...materialRoutes,
        ...wabpageRoutes
      ]
    },
    {
      path: "*",
      component: NotFound
    },
    {
      path: "/record",
      name: "record",
      component: Record
    },
    {
      path: "/idfile",
      name: "idfile",
      component: Idfile
    },
    {
      path: "/assets",
      name: "assets",
      component: Assets
    },
    {
      path: "/certificate",
      name: "certificate",
      component: certificate
    }
  ],
  mode: "history"
});

router.beforeEach((to, from, next) => {
  // 页面无条件放行
  if (to.path === "/login" || to.path == "/record" || to.path == "/certificate" || to.path == "/idfile" || to.path == "/assets") {
    return next();
  } else if (!store.state.isLogin && to.path != "/login") { // 没有登录，重定向到登录页
    window.location.href = "/login";
  }
  // 没有home页面，重定向到登录页
  if (to.path === "/") {
    return next("/login");
  }
  next();
});

export default router;
