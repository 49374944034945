import { authRedirect } from "./helper";

const wabpageTag = () => import("@/views/application/index");
const plugCode = () => import("@/views/application/plugCode");
const webSite = () => import("@/views/application/website");
const wabDeploy = () => import("@/views/application/deploy");
const wabMessage = () => import("@/views/application/message");

/** @type {import("vue-router").RouteConfig[]} */
let routes = [
  {
    path: "/wabpage/site",
    component: wabpageTag,
    meta: { pageTitle: "网页赋码" }
  },
  {
    path: "/wabpage/site/plugCode",
    component: plugCode,
    meta: { pageTitle: "网页赋码自动插件" }
  },
  {
    path: "/wabpage/site/website",
    component: webSite,
    meta: { pageTitle: "首页网址" }
  },
  {
    path: "/wabpage/site/deploy",
    component: wabDeploy,
    meta: { pageTitle: "元素配置" }
  },
  {
    path: "/wabpage/site/message",
    component: wabMessage,
    meta: { pageTitle: "访客留言" }
  }
];

export default routes;
