import Vue from "vue";
import Vuex from "vuex";
import { userInfoApi } from "../services/api";
import { showError } from "@/utils/error";

import router from "@/router";

let store;

Vue.use(Vuex);

/**
 * TODO:
 * 1. 刷新时通过 ms_session 重新获取用户信息
 * 2. 修改当前用户信息后重新获取用户信息
 * 3. 修改当前用户角色权限后重新获取权限
 * 4. 在 sessionStorage 中仅保存 ms_session，用户信息储存在 vuex
 */
let initState = (() => {
  let state;
  try {
    state = JSON.parse(window.sessionStorage.getItem("state"));
  } catch (e) {
    state = {};
  }
  console.log("进来了",state);
  window.addEventListener("beforeunload", updateSessionStorage, false);
  return state;
})();
export function updateSessionStorage() {
  window.sessionStorage.setItem("state", JSON.stringify(store.state));
}

store = new Vuex.Store({
  state: {
    isLogin: false,
    ms_session: "",
    id: "",
    platform: "", // designer | merchant
    user: {
      username: "匿名",
      meb_phone: "",
      company_name: "",
      funcKeys: []
    },
    mpkey: "",
    company_node: "", // 企业节点
    registerTime: "", // 注册时间
    entBasicInfo: {}, // 注册信息
    company_product_version: 100, // 100 标准版 200 高级版
    ...initState
  },
  getters: {
    ms_session(state) {
      return state.ms_session;
    }
  },
  mutations: {
    /** @param {K.User.LoginResData} data */
    login(state, data) {
      state.isLogin = true;
      state.ms_session = data.ms_session;
      state.user.username = data.manager_username;
      state.user.userId = data.meb_id;
      state.user.meb_phone = data.meb_phone;
      state.user.company_name = data.company.company_name;
      state.company_product_version = data.company.company_product_version;
      state.mpkey = data.meb_mpkey;
      updateSessionStorage()
    },
    logout(state) {
      state.isLogin = false;
      state.ms_session = ""; 
      state.user.username = "";
      state.user.userId = "";
      state.mpkey = "";
      state.user.company_name = "";
      state.user.meb_phone = "";
      state.company_product_version = 100;
    },
    updateSession(state, newSession) {
      state.ms_session = newSession;
    },
    setEntBasicInfo(state, data) {
      state.entBasicInfo = data;
    },
    setCompanyNode(state, data) {
      state.company_node = data;
    },
    setRegisterTime(state, data) {
      state.registerTime = data;
    }
  },
  actions: {
    async login(context, loginInfo) {
      try {
        let res = await userInfoApi.login({
          ...loginInfo
        });
        context.commit("login", res.data);
        context.dispatch("saveUserSession");
        router.push({ path: "/home" });
      } catch (e) {
        showError(e);
      }
    },
    logout(context) {
      context.commit("logout");
      context.dispatch("saveUserSession");
    },
    saveUserSession({ state }) {
      let store = window.sessionStorage;
      store["isLogin"] = state.isLogin;
      store["ms_session"] = state.ms_session;
      store["userInfo"] = JSON.stringify(state.user);
      store["mpkey"] = state.mpkey;
      store["isJump"] = false;
    },
    updateSession(context, newSession) {
      context.commit("updateSession", newSession);
    }
  }
});


export default store;
