import Vue from "vue";

import { formatDate, formatPhone, isNull, moneyFormat } from "../utils/utils.js";

Vue.filter("date", formatDate);
Vue.filter("dateTime", timeStamp => formatDate(timeStamp, "datetime"));

Vue.filter("tableNull", isNull);

Vue.filter("moneyFormat", moneyFormat);
Vue.filter("formatPhone", formatPhone);

Vue.filter("line", item => item === "" ? "--" : item);
