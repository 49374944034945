<template>
  <div>
    <div class="tip flex bg-fff space-between margin-bottom">
      <div class="status">
        服务状态：<span
          :class="{ green: info.company_enabled == 1, red: info.company_enabled == 2 }"
          class="green"
        >{{ serviceStatus() }}</span>
      </div>
      <div class="time">
        服务时间：<span class="yellow">{{ info.company_starttime }}-{{ info.company_endtime }}</span>
      </div>
    </div>
    <!--基本资料-->
    <div class="info bg-fff margin-bottom">
      <div class="info-title">企业基本资料</div>
      <div class="content">
        <div class="item">
          企业名称：<span>{{ info.company_registe_info.entBasicInfo.orgName }}</span>
        </div>
        <div class="item">
          联系人姓名：<span>{{ info.company_registe_info.entBasicInfo.contactName }}</span>
        </div>
        <div class="item">
          行业门类：<span>{{ info.company_registe_info.entBasicInfo.industryCategory }}</span>
        </div>
        <div class="item">
          单位性质：<span>{{ info.company_registe_info.entBasicInfo.orgNature }}</span>
        </div>
        <div class="item">
          联系人手机：<span>{{ info.company_registe_info.entBasicInfo.contactPhone }}</span>
        </div>
        <div class="item">
          行业大类：<span>{{ info.company_registe_info.entBasicInfo.industrySpecific }}</span>
        </div>
        <div class="item">
          {{ info.company_registe_info.entBasicInfo.orgCrtType }}：<span>{{
            info.company_registe_info.entBasicInfo.orgCrtCode
          }}</span>
        </div>
        <div class="item" style="width: 80%">
          营业执照：<span class="blue query" @click="dialogVisible = true">查看营业执照</span>
        </div>
        <div class="item" style="width: 50%">
          单位详细地址：<span>{{ info.company_registe_info.entBasicInfo.orgAddr }}</span>
        </div>
      </div>
    </div>
    <!--块状-->
    <div class="flex space-between" style="align-items: baseline">
      <div class="box bg-fff">
        <div class="info-title">标识状态</div>
        <div class="center">
          <i class="iconfont icon-chenggong" />
          <div>您已经成功通过企业标识</div>
        </div>
        <div class="item">标识机构：<span>中国信息通信研究院</span></div>
        <div class="item">
          标识时间：<span>{{ info.company_registe_time }}</span>
        </div>
      </div>
      <div class="box bg-fff" style="margin: 0 10px">
        <div class="info-title flex space-between">
          <span>企业节点</span>
          <!-- <span class="blue query" @click="queryCode">查询</span> -->
        </div>
        <div class="node blue">{{ info.company_node }}</div>
        <div class="node-box">
          <div class="item">
            <span>注册服务机构：</span>
            <span>工业互联网标识解析国家顶级节点--北京</span>
          </div>
        </div>
      </div>
      <div class="box bg-fff">
        <div class="info-title flex space-between">
          <span>标识码</span>
          <span class="blue query" @click="queryIdentity">标识码管理</span>
        </div>
        <div class="code flex">
          <div class="flex">
            <span class="blue">{{ info.identity_total }}</span>
            <span>标识码数量</span>
          </div>
          <div class="flex">
            <span class="blue">{{ info.identity_visit_total }}</span>
            <span>标识码访问量</span>
          </div>
        </div>
      </div>
    </div>

    <!--查看营业执照-->
    <div class="dialog" v-if="dialogVisible">
      <div class="content">
        <img :src="info.company_registe_info.entBasicInfo.orgCrtImg">
        <div class="close" @click="dialogVisible = false">
          <i class="iconfont icon-guanbi2fill" />
        </div>
      </div>
    </div>
    <div class="record">
      <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备2021024496号</a>
    </div>
  </div>
</template>

<script>
import { companyApi } from "../services/api";
import { formatDate } from "../utils/time";
export default {
  data() {
    return {
      info: {
        company_registe_info: {
          entBasicInfo: {}
        }
      },
      dialogVisible: false,
      status: ["关闭", "服务中", "已到期"]
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    async getInfo() {
      let { data } = await companyApi.info();
      this.info = data;

      this.$store.commit("setEntBasicInfo", data.company_registe_info.entBasicInfo);
      this.$store.commit("setCompanyNode", data.company_node);
      this.$store.commit("setRegisterTime", data.company_registe_time);

      this.info.company_starttime = formatDate(this.info.company_starttime, "yyyy/MM/dd");
      this.info.company_endtime = formatDate(this.info.company_endtime, "yyyy/MM/dd");
      this.info.company_registe_time = formatDate(this.info.company_registe_time, "yyyy年MM月dd日");
    },
    queryCode() {
      window.open(`https://idfactory.cn/whois/ui/searchResult?identity=${this.info.company_node}`);
    },
    queryIdentity() {
      this.$router.push(`/manage/identity`);
    },
    serviceStatus() {
      return this.status[this.info.company_enabled];
    }
  }
};
</script>

<style lang="less" scoped>
.flex {
  display: flex;
  align-items: center;
}
.record {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 12px;
  font-size: 12px;
  text-align: center;
}
a {
  text-decoration: none;
  color: #999;
}
.bg-fff {
  background: #fff;
  border-radius: 6px;
}
.space-between {
  justify-content: space-between;
}
.yellow {
  color: #ff8800;
}
.green {
  color: #06bf00;
}
.blue {
  color: #1285d6 !important;
}
.red {
  color: red;
}
.margin-bottom {
  margin-bottom: 16px;
}
.tip {
  padding: 15px 16px;
  color: #011539;
  font-size: 14px;
}
.info-title {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #011539;
  margin-bottom: 20px;
  .query {
    font-size: 14px;
    font-weight: normal;
    cursor: pointer;
  }
}
.info {
  padding: 22px 20px;
  .content {
    padding: 27px 17px;
    background-color: #f3f4f7;
    font-size: 14px;
    .item {
      margin-bottom: 16px;
      width: 30%;
      display: inline-block;
      span {
        color: #7b7e8c;
      }
      .query {
        cursor: pointer;
        font-weight: 400;
      }
    }
  }
}
.box {
  flex-direction: column;
  padding: 22px;
  width: 30%;
  min-height: 218px;
  font-size: 14px;
  color: #011539;
  .center {
    text-align: center;
    padding-top: 25px;
    margin-bottom: 37px;
    line-height: 14px;
    .iconfont {
      font-size: 49px;
      color: #06bf00;
    }
    div {
      margin-top: 17px;
    }
  }
  .item {
    margin-bottom: 16px;
    line-height: 14px;
    span {
      color: #7b7e8c;
      line-height: 20px;
    }
  }
  .item:last-child {
    margin-bottom: 0px;
  }
  .node {
    text-align: center;
    font-size: 38px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 38px;
    margin: 52px 0 31px 0;
  }
  .node-box {
    background-color: #f3f4f7;
    padding: 0px 9px;
    height: 64px;
    margin-bottom: 1px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    .item {
      margin-bottom: 0;
      display: flex;
      flex-flow: row nowrap;
      span:nth-child(1) {
        flex-shrink: 0;
      }
    }
  }

  .code {
    margin-top: 52px;
    justify-content: space-around;
    margin-bottom: 26px;
    .blue {
      font-size: 38px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 38px;
      margin-bottom: 9px;
    }
    .flex {
      flex-direction: column;
      height: 101px;
      width: 50%;
      justify-content: center;
    }
    .flex:first-child {
      border-right: 1px solid #e4e4e4;
    }
  }
}
.dialog {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9;
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    img {
      max-width: 100%;
      max-height: 90vh;
      object-fit: cover;
    }
    .close {
      margin-top: -90vh;
      cursor: pointer;
      .iconfont {
        color: #f7f7f7;
        font-size: 28px;
        margin-left: 12px;
      }
    }
  }
}
</style>
