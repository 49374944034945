// 用于百度统计时间换算 stamp为真时返回时间戳
export function getTimer(stamp, timer) { // 计算时间 单位为天
  let today = new Date().getTime();
  let m = (24 * 60 * 60 * 1000) * (timer ? timer : 0);
  let T = new Date(today + m);
  let year = T.getFullYear();
  let month = (Number(T.getMonth()) + 1) > 9 ?
    (Number(T.getMonth()) + 1) : `0${Number(T.getMonth()) + 1}`;
  let day = T.getDate() > 9 ? T.getDate() : `0${T.getDate()}`;
  // let hour = T.getHours()>9?T.getHours():"0"+T.getHours()
  // let min = T.getMinutes()>9?T.getMinutes():"0"+T.getMinutes()
  // let sec = T.getSeconds()>9?T.getMinutes():"0"+T.getMinutes()
  if (stamp) {
    return today + m;
  }
  return `${year}-${month}-${day}`;

}
export function timeCount(timer, stamp) {
  // 秒换算时分秒 stamp?毫秒:秒
  let today = new Date().getTime();
  let date = "";
  let times = 0;
  if (stamp) {
    times = parseInt((today - timer) / 1000);
  } else {
    times = Number(timer);
  }
  let oneDay = 86400;
  let oneHour = 3600;
  let oneMinute = 60;
  let sec = times % 60; // 秒
  let day = Math.floor(times / oneDay); // 天
  let hour = Math.floor(times / oneHour); // 时
  let minute = Math.floor(times / oneMinute) % 60;// 分
  if (timer > 0) {
    date = `${hour > 9 ? hour : `0${hour}`}:${minute > 9
      ? minute : `0${minute}`}:${sec > 9 ? sec : `0${sec}`}`;
  } else {
    date = "00:00:00";
  }
  return date;
}

/**
 * 时间戳转时间, 日期等
 * - `format`
 *    - `"date"` > `"yyyy-MM-dd"`
 *    - `"datetime"` > `"yyyy-MM-dd hh:mm:ss"`
 *    - `"time"` > `"hh:mm:ss"`
 *    - 自定义时间格式
 * @param {number | string} timestamp 时间戳
 * @param {"date" | "datetime" | "time"} [format] 格式说明
 */
export function formatDate(timestamp, format) {
  if (timestamp == 0 || !timestamp) return "--";
  if (typeof timestamp == "string" && /^\d+$/.test(timestamp)) {
    timestamp = Number(timestamp);
  }

  format = {
    undefined: "yyyy-MM-dd",
    date: "yyyy-MM-dd",
    datetime: "yyyy-MM-dd hh:mm:ss",
    time: "hh:mm:ss"
  }[format] || format;


  const date = new Date(timestamp);

  if (isNaN(date) || date == null) {
    return "--";
  }

  return format
    .replace("yyyy", date.getFullYear().toString().padStart(4, "0"))
    .replace("MM", (date.getMonth() + 1).toString().padStart(2, "0"))
    .replace("dd", date.getDate().toString().padStart(2, "0"))
    .replace("hh", date.getHours().toString().padStart(2, "0"))
    .replace("mm", date.getMinutes().toString().padStart(2, "0"))
    .replace("ss", date.getSeconds().toString().padStart(2, "0"));
}

// /**
// * @param {object} value
// * @param {number} [value.month]
// * @param {number} [value.date]
// * @param {number} [value.hours]
// * @param {number} [value.minutes]
// * @param {number} [value.seconds]
// * @param {number} [value.ms]
// */
// export function timeOf(value){
// let timespan = 0;
// if(value.ms) timespan += value.ms;
// if(value.seconds) timespan += value.seconds * 1000;
// if(value.minutes) timespan += value.minutes * 1000 * 60;
// if(value.hours) timespan += value.hours * 1000 * 60 * 60;
// if(value.date) timespan += value.date * 1000 * 60 * 60 * 24;
// if(value.month) timespan += value.month * 1000 * 60 * 60 * 24 * 30;
// return timespan;
// }

export class DateTime {
  constructor(date) {
    this._ = date ? new Date(date) : new Date();
  }
  /**
   * @param {object} value
   * @param {number} [value.month]
   * @param {number} [value.date]
   * @param {number} [value.hours]
   * @param {number} [value.minutes]
   * @param {number} [value.seconds]
   * @param {number} [value.ms]
   */
  set(value) {
    if (value.month != null) this._.setMonth(value.month - 1);
    if (value.date != null) this._.setDate(value.date);
    if (value.hours != null) this._.setHours(value.hours);
    if (value.minutes != null) this._.setMinutes(value.minutes);
    if (value.seconds != null) this._.setSeconds(value.seconds);
    if (value.ms != null) this._.setMilliseconds(value.ms);
    return this;
  }
  add({ month = 0, date = 0, hours = 0, minutes = 0, seconds = 0, ms = 0 }) {
    let value = {
      month: this._.getMonth() + month + 1,
      date: this._.getDate() + date,
      hours: this._.getHours() + hours,
      minutes: this._.getMinutes() + minutes,
      seconds: this._.getSeconds() + seconds,
      ms: this._.getMilliseconds() + ms
    };
    return this.set(value);
  }
  sub({ month = 0, date = 0, hours = 0, minutes = 0, seconds = 0, ms = 0 }) {
    return this.add({
      month: -month,
      date: -date,
      hours: -hours,
      minutes: -minutes,
      seconds: -seconds,
      ms: -ms
    });
  }
  /** 取 0 点的时间 */
  roundDate() {
    this._.setHours(0, 0, 0, 0);
    return this;
  }
  valueOf() {
    return this._.valueOf();
  }
  getTime() {
    return this._.getTime();
  }
}
