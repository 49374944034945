<template>
  <div class="content">
    <img src="https://resource.tuixb.cn/test/mp_73fdb0e0-56b0-11ea-910e-792184e2a553/default/1b59efcd-458c-4858-8f51-33c20145ab7c.png" alt="">
    <div class="text_cont">您购买的站点已到期</div>
    <div class="text_cont">如需恢复正常使用，请拨打客服热线续费</div>
  </div>
</template>
<script>
export default {
};
</script>
<style scoped>
.content{
  width: 100%;
  height: 100vh;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.content img{
  width: 412px;
  height: 220px;
  margin-bottom: 20px;
}
.text_cont{
 line-height: 20px;
 font-size: 12px;
  color: #606266
}

</style>
