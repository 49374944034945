<template>
  <div class="wrap">
    <div class="wrap-header no-style">
      <div class="header-content">
        <div class="liuhai-icon" />

        <div class="web-text">
          <p class="web-name">
            <img src="../images/sfvsd.png" />
          </p>
          <div class="web-td">
            <span>权威存证</span>
            <span>· 防伪溯源</span>
          </div>
        </div>
        <div class="web-status">
          <img src="../images/jb_dun.png" />
        </div>
      </div>
    </div>
    <div class="product-box">
      <div class="product clearfix">
        <div class="p-img">
          <img src="../images/mini-logo.png" class="showImg" />
        </div>
        <div class="p-info new-info">
          <!-- <img
            class="c-icon"
            src="https://cctv-gw.kexinid.com/trace/static/images/icon2.png"
          > -->
          <div class="p-info-title">国家工业互联网标识</div>
          <div class="p-info-code">{{ params.identification_code }}</div>
          <div class="p-info-btn">
            <a @click="href">前往国家顶级节点查询</a>
          </div>
        </div>
      </div>
      <div class="detail clearfix">
        <div class="p-detail-left">
          <p>首次查询时间</p>
          <div>{{ scan_code_time | dateTime }}</div>
        </div>
        <div class="p-detail-right">
          <p>第{{ scan_code_num }}次扫码</p>
          <!-- <div>
            <a
              href="https://cctv-gw.kexinid.com/view/record/88.111.12/RSJNRLJJ1-114101754918"
            >查看详情 ></a>
          </div> -->
        </div>
      </div>
    </div>

    <div class="tab-box posR">
      <div class="tab-div">
        <div @click="tableChange(1)" class="tab-item" :class="isShow == 1 && 'on'">
          <a>
            <p>
              {{ typeText[identity_type] }}
            </p>
          </a>
          <span />
        </div>

        <div @click="tableChange(2)" class="tab-item" :class="isShow == 2 && 'on'">
          <a>
            <p>企信档案</p>
          </a>
          <span />
        </div>
      </div>
    </div>
    <div class="tab-info">
      <div id="second-1" class="posR" :class="isShow == 2 && 'dn'">
        <div style="margin-bottom: 0">
          <div
            class="tab-info-item"
            v-for="item in dataList"
            :key="item.identity_cat_id"
            :style="
              (item.attr_name == '商品图片' || item.attr_alias == 'goodsVideo') &&
              'display: flex;justify-content: space-between;'
            "
          >
            <div class="label">{{ item.attr_name }}</div>

            <img v-if="item.attr_name == '商品图片'" class="label-img" :src="item.attrv_value" />
            <!-- 资产标识、数字资产图片显示 -->
            <template v-else-if="item.attr_type == 8">
              <div class="img-container">
                <img
                  v-for="(item, index) in item.attrv_values"
                  class="label-img"
                  :key="index"
                  :src="item"
                />
              </div>
            </template>
            <div v-else-if="item.attr_type == 2" class="label-info rich-container">
              <div v-html="item.attrv_value"></div>
            </div>
            <div v-else-if="item.attr_alias == 'goodsVideo'">
              <video class="label-video" autoplay controls v-if="item.attrv_video_type == 1" muted>
                <source type="video/mp4" :src="item.attrv_value" />
              </video>
              <embed v-else-if="src" class="label-video" :src="src" width="400" height="300" />
              <div
                hidden
                class="label-video"
                v-if="item.attrv_video_type == 2"
                v-text="item.attrv_value"
              />
            </div>
            <div v-else class="label-info">
              {{ item.attrv_value }}
            </div>
          </div>
          <!-- <div class="tab-info-item">
            <div class="label">产品介绍：</div>

            <div class="changText">
              1、原料是新疆博湖辣椒，博湖辣椒2003年被农业部授于绿色食品，国家地理标志产品，中国十大名椒，中欧第二批地理标志互认产品（排名39位），辣椒红素和维生
              素C、钙含量高居辣椒榜首。2、乳酸菌发酵，经中国食品发酵工业研究院检测发现谢谢酱原酱中富
              含四种天然乳酸菌：戊糖乳杆菌、耐乙醇片球菌、消化伴生乳杆菌、那慕尔促生乳杆菌;另在辣椒酱系列产品中添加两种CICC乳酸菌：CICC®6009植物乳杆菌、CICC
              ®6024瑞士乳杆菌。使产品具有优化肠道微生物状态，调节肠道菌群多样性，调节机体免疫，降低胆固醇、抗炎、抗氧化、等功效。3、低盐，无人工色素香精添加。不含防腐剂。4、纯
              瘦牛肉粒每罐酱含量不低于30%
              5、各种产品均获得了CMA标识和Cnas标识，其中乳酸菌鲜椒牛肉酱农残及兽残检测合格，中卫安北京标识中心授权使用安全食品标志。
            </div>
          </div> -->
        </div>
        <div
          v-if="(identity_goods == {} ? false : true) && identity_goods.goods_is_show"
          class="price"
        >
          <span v-if="identity_goods.goods_money">{{ "￥" + identity_goods.goods_money }}</span>
          <span v-if="identity_goods.goods_money"
            ><a style="color: #fff" :href="'http://' + identity_goods.goods_url">立即购买</a></span
          >
        </div>
        <div class="message_board">
          <span>在线留言</span>
          <el-form ref="form" :model="form" :rules="rules" style="width: 100%">
            <el-form-item prop="co_name">
              <el-input v-model="form.co_name" class="message_input" placeholder="姓名" />
            </el-form-item>
            <!-- <div class="tip">姓名不能为空</div> -->
            <el-form-item prop="co_mobile">
              <el-input v-model="form.co_mobile" class="message_input" placeholder="电话" />
            </el-form-item>
            <!-- <div class="tip">电话不能为空</div> -->
            <el-form-item prop="co_content">
              <el-input
                type="textarea"
                class="message_input"
                :rows="4"
                placeholder="留言内容"
                v-model="form.co_content"
              />
            </el-form-item>

            <!-- <div class="tip">留言内容不能为空</div> -->
            <div class="submit" @click="onSubmit">提 交</div>
          </el-form>
        </div>
        <div class="node_info">
          <p>
            此信息在
            {{ identity_created | dateTime }} 存证于国家工业互联网二级节点
          </p>
          <!-- <div>存证ID：did:bid:sqtx:sf27WAcyYejBdAKEJeP1nFzxDWV6qBCK3</div> -->
        </div>
      </div>
      <div id="second-3" class="label-width" :class="isShow == 1 && 'dn'">
        <div class="label-detail posR qixin">
          <img :src="company_record" style="width: 100%" />
        </div>
      </div>
    </div>
    <div class="mask" v-if="maskShow">提交成功</div>

    <!--区块链标识  -->
    <div class="blockchain" v-if="identity_chain_status == 3" @click="openAntDialog()" />
    <!--区块链内容-->
    <el-dialog
      :visible="antDialogVisible"
      :lock-scroll="false"
      width="80%"
      height="80%"
      :align-center="true"
      :show-close="false"
    >
      <div class="ant_img_box">
        <div class="top_ant">
          <img
            src="https://assets-service.obs.cn-south-1.myhuaweicloud.com/test/mp_82382310-c251-11ed-a857-e14019a61c1e/186f0d9a-95e9-46d2-9381-c75ddf6ed414.png"
            alt=""
          />
          <div>
            <span class="title">区块链</span>
            <span class="desc">ANT BLOCKCHAIN</span>
          </div>
        </div>
        <div class="text_desc">本信息已在区块链存证， 区块链为您的购物保驾护航。</div>
        <div class="qrcode_img_box">
          <img class="qrcode_img" :src="chainQrcode" alt="" />
        </div>
        <div class="copy-link" @click="copyChainLink()">复制链接</div>
        <div class="qrcode_desc">请使用支付宝扫码查看区块链内容。</div>
        <div class="close_btn" @click="closeAntDialog()">我知道了</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { webSiteApi, identityApi } from "@/services/api";
import { v1 as uuidv1 } from "uuid";
import { phone } from "../utils/regExp";
let _regx = phone;
export default {
  data() {
    return {
      antDialogVisible: false,
      identity_chain_status: "",
      typeText: {
        0: "产品信息",
        1: "网页信息",
        2: "商品信息",
        3: "资产信息",
        4: "资产信息",
      },
      chainQrcode: "",
      isShow: 1,
      identity_created: "",
      company_record: "",
      scan_code_time: "",
      scan_code_num: "",
      identity_type: "",
      identity_goods: {},
      src: "",
      maskShow: false,
      form: {
        co_name: "",
        co_mobile: "",
        co_content: "",
        co_identity_id: "",
      },
      params: {
        identification_code: "",
        language: "",
        path: "",
        lastPath: "",
        time: "",
        mpkey: "",
        clienttype: "wxh5",
        reqId: "",
        referer_type: "",
      },
      dataList: [],
      rules: {
        co_name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        co_mobile: [
          { required: true, message: "请输入电话", trigger: "blur" },
          {
            pattern: _regx,
            message: "请输入正确的电话",
          },
        ],
        co_content: [{ required: true, message: "请输入留言内容", trigger: "blur" }],
      },
    };
  },
  created() {
    console.log("this.$route.query.handle", this.$route.query.handle);
    this.getData();
    document.title = "国家工业互联网数字档案";
    let _resizeWindow = function () {
      let $html = document.getElementsByTagName("html")[0];
      let offsetWidth = document.body.clientWidth;
      if (!offsetWidth) return;
      document.documentElement.style.fontSize = `100px`;
      // if (window.screen.availWidth < 1200) {
      //   document.documentElement.style.fontSize = `100px`;
      // } else {
      //   document.documentElement.style.fontSize = `${(100 / 750) * offsetWidth}px`;
      // }
      document.body.style.minWidth = "auto";
    };
    _resizeWindow();
    this.params.language = navigator.language;
    this.params.path = document.URL;
    this.params.reqId = uuidv1();
    this.params.lastPath = document.referrer;
    this.params.time = new Date().getTime();
    this.params.referer_type = this.$route.query.number;
  },
  mounted() {
    window.addEventListener("beforeunload", this.handleVisiable);
  },
  methods: {
    async handleVisiable() {
      console.log(this.params);
      this.params.bc = "";
      this.params.time = new Date().getTime();
      this.params.reqId = uuidv1();
      await webSiteApi.onunload(this.params);
    },
    async getData() {
      let { code, data } = await webSiteApi.detail({
        identity_handle: this.$route.query.handle,
      });
      this.params.identification_code = data.identity_handle;
      this.params.mpkey = data.identity_mpkey;
      this.identity_created = data.identity_created;
      this.identity_chain_status = data.identity_chain_status;
      this.company_record = data.company_record;
      this.scan_code_time = data.scan_code_time;
      this.scan_code_num = data.scan_code_num;
      this.identity_type = data.identity_type;
      this.form.co_identity_id = data.identity_id;
      this.identity_goods = data.identity_goods;
      console.log(this.identity_goods);
      if (data.identity_type) {
        this.dataList = data.identity_attr_info.filter((item) => item.attrv_value);
        this.dataList.forEach((item, index) => {
          if (item.attr_alias == "goodsDetail") {
            this.dataList.splice(index, 1);
          }
          if (item.attr_alias == "goodsVideo") {
            if (item.attrv_video_type == 2) {
              setTimeout(() => {
                let arr = document.getElementsByTagName("iframe");
                this.src = arr[0].src;
                console.log("this.src", this.src);
              });
              // this.src = src.toString();
            } else {
              this.src = item.attrv_value;
            }
          }
        });
      } else {
        this.dataList = data.identity_attr_info;
      }
      if (code == 200 && this.$route.query.number) {
        this.buriedPoint();
      }
    },
    async buriedPoint() {
      await webSiteApi.onLoad(this.params);
      this.$router.push({ query: { handle: this.$route.query.handle } });
      this.$nextTick(() => {
        this.getData();
      });
    },
    tableChange(index) {
      this.isShow = index;
    },
    linkTo() {
      location.href = this.identity_goods.goods_url;
    },
    onSubmit() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          let { code } = await webSiteApi.save(this.form);
          if (code == 200) {
            this.$refs["form"].resetFields();
            this.maskShow = true;
            setTimeout(() => {
              this.maskShow = false;
            }, 3000);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    href() {
      location.href = `http://snms.cdiisp.com:60/snms/ui/query?id=${this.params.identification_code}`;
    },
    openAntDialog() {
      identityApi
        .getChainQrcode({
          businessType: this.identity_type,
          businessId: this.form.co_identity_id,
          company_node: this.params.identification_code,
        })
        .then((res) => {
          if (this.$route.query.fromMiniProgram) {
            this.chainQrcode = res.data.qrcode;
            this.antDialogVisible = true;
            // this.copyTextToClipboard(res.data.url)
            // this.$message.success("链接已复制，请到浏览器查看！")
          } else {
            location.href = res.data.url;
          }
        });
    },
    copyTextToClipboard(text) {
      // 创建一个临时的textarea元素
      var textArea = document.createElement("textarea");

      // 设置textarea的值为你想要复制的文本
      textArea.value = text;

      // 将textarea添加到文档中（虽然它是隐藏的，但它需要被添加到DOM中以便能够执行复制操作）
      document.body.appendChild(textArea);

      // 选择文本
      textArea.focus();
      textArea.select();

      try {
        // 复制文本到剪贴板
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        console.log("Copying text command was " + msg);
      } catch (err) {
        console.error("Oops, unable to copy", err);
      }

      // 移除textarea
      document.body.removeChild(textArea);
    },

    closeAntDialog() {
      this.antDialogVisible = false;
    },
    copyChainLink() {
      console.log(navigator);
      navigator.clipboard
        .writeText(this.chainQrcode)
        .then(() => {
          this.$message.success("复制成功");
        })
        .catch((error) => {
          console.log(error);
          const input = document.createElement("input");
          document.body.appendChild(input);
          input.setAttribute("value", this.chainQrcode);
          input.select();
          if (document.execCommand("copy")) {
            document.execCommand("copy");
          }
          document.body.removeChild(input);
          this.$message.success("复制成功");
        });
    },
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: normal;
  // vertical-align: baseline;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  word-break: break-all;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul,
li {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* custom */
a {
  color: #7e8c8d;
  text-decoration: none;
  -webkit-backface-visibility: hidden;
}

html,
body {
  width: 100%;
  overflow-x: hidden;
}

.wrap {
  padding-bottom: 0.2rem;
  background: url("../images/bg15.png") no-repeat !important;
  background-size: cover !important;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

body {
  color: #333;
  line-height: 1;
  min-width: none;
}

html {
  overflow-y: scroll;
  overflow-x: hidden;
}

.mt10 {
  margin-top: 10px;
}

.pt10 {
  padding-top: 10px;
}

.posR {
  position: relative;
}

.pt20 {
  padding-top: 20px;
}

.pb20 {
  padding-bottom: 20px;
}

.mt20 {
  margin-top: 20px;
}

.mb20 {
  margin-bottom: 20px;
}

.posA {
  position: absolute;
}

.pointer {
  cursor: pointer;
}

.dn {
  display: none !important;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

img {
  image-rendering: -moz-crisp-edges;
  /* Firefox */
  image-rendering: -o-crisp-edges;
  /* Opera */
  image-rendering: -webkit-optimize-contrast;
  /*Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  /* IE (non-standard property) */
}

.clearfix::after {
  clear: both;
  content: ".";
  display: block;
  height: 0;
  visibility: hidden;
}

.no-data-2 {
  padding: 0.5rem 0;
  background: #fff;
  text-align: center;
}

.no-data-2 img {
  width: 0.6rem;
}

.no-data-2 p {
  font-size: 0.14rem;
  color: #999;
  padding: 0.2rem;
}

.web-td {
  display: inline-block;
  width: auto !important;
}

.tab-info > div {
  border-bottom-left-radius: 0.1rem;
  -webkit-border-bottom-left-radius: 0.1rem;
  border-bottom-right-radius: 0.1rem;
  -webkit-border-bottom-right-radius: 0.1rem;
  overflow: hidden;
}

.p-info-code {
  text-align: Center;
  line-height: 0.2rem;
}

.p-info-btn {
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

.node_info {
  background: none !important;
  color: #c89469;
}

.node_info p {
  font-size: 0.13rem;
  line-height: 0.2rem;
  // margin-bottom: 0.1rem;
}

.node_info div {
  font-size: 0.13rem;
  line-height: 0.2rem;
}

.company_node {
  margin-top: 0.1rem;
  padding: 0.15rem;
  background: #fff;
}

.c-icon {
  position: absolute;
  width: 0.3rem;
}

.new-info .p-info-title {
  // padding-left: 0.4rem;
  padding-top: 0.05rem;
}

.new-info .p-info-code {
  // padding-left: 0.4rem;
  font-size: 0.12rem;
  text-align: left;
  padding-top: 0.05rem;
}

.new-info .p-info-btn {
  width: auto;
  font-size: 0.13rem;
}

.changText {
  padding-left: 1rem;
  line-height: 0.3rem;
  min-height: 0.3rem;
  font-size: 0.14rem;
  color: #606060;
  text-align: left;
}

.blockchain {
  position: fixed;
  right: -0.08rem;
  width: 0.6rem;
  height: 0.7rem;
  top: 20%;
  cursor: pointer;
  background: url("https://assets-service.obs.cn-south-1.myhuaweicloud.com/test/mp_82382310-c251-11ed-a857-e14019a61c1e/20533f16-79d4-4715-bae2-ddc1bca6b783.png")
    no-repeat;
  background-size: contain;
  z-index: 20;
}

@media screen and (min-width: 750px) {
  .wrap {
    padding-bottom: 0.2rem;
    background: url("../images/bg15.png") no-repeat !important;
    background-size: cover !important;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 375px;
    margin: 0 auto;
    position: relative;
  }
  .blockchain {
    position: absolute;
  }
}

.wrap-header {
  width: 100%;
  height: 2.6rem;
  background-size: 100% 100%;
  padding-top: 0.2rem;
}

.header-content {
  padding: 0rem 0.3rem;
  position: relative;
}

.web-name {
  font-size: 0;
  width: 2rem;
  padding-top: 0.3rem;
  transform: translateY(-0.2rem);
}

.web-name img {
  width: 100%;
}

.web-td {
  border: 0.01rem solid #fff;
  background: rgba(255, 255, 255, 0.3);
  padding: 0.06rem 0.1rem;
  font-size: 0.1rem;
  border-radius: 5rem;
  color: #fff;
  width: 1.9rem;
  margin-top: -3rem;
  transform: translateY(-0.75rem);
  line-height: 0.15rem;
  padding-top: 0.07rem;
}

.web-td span {
  padding: 0 0.03rem;
}

.web-status {
  width: 4.2rem;
  height: 4.2rem;
  position: absolute;
  right: -1.4rem;
  top: -0.7rem;
  background: url("https://cctv-gw.kexinid.com/trace/static/images/wrap-bg15.png") no-repeat;
  background-size: 100% 100%;
  color: #fff;
  font-size: 0;
}

.web-status img {
  width: 0.9rem;
  height: 0.9rem;
  position: absolute;
  top: 50%;
  margin-top: -0.45rem;
  left: 50%;
  margin-left: -0.45rem;
}

.product-box {
  width: 3.5rem;
  margin: 0 auto;
  background: #fff;
  margin-top: -0.7rem;
  border-radius: 0.08rem;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  z-index: 11;
}

.product {
  padding: 0.2rem;
}

.p-img {
  width: 1.1rem;
  height: 1rem;
  float: left;
}

.p-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.05rem;
}

.s-icon {
  width: 0.2rem;
  height: 0.2rem;
}

.p-info-title {
  color: #091b63;
  font-size: 0.13rem;
}

.p-info-title img {
  vertical-align: -0.06rem;
  margin-right: 0.05rem;
}

.p-info {
  padding-left: 1.3rem;
}

.p-info-code {
  font-size: 0.15rem;
  padding-top: 0.1rem;
  color: #333;
}

.p-info-btn {
  padding: 0.09rem 0.05rem;
  background: #f09b4e;
  color: #fff;
  font-size: 0.1rem;
  width: 1.4rem;
  text-align: center;
  border-radius: 5rem;
  margin-top: 0.15rem;
}

.p-detail-left {
  float: left;
  font-size: 0.12rem;
}

.p-detail-right {
  float: right;
  font-size: 0.12rem;
}

.detail {
  background: #f3f5fc;
  padding: 0.1rem;
}

.p-detail-left p {
  color: #9b9b9b;
  line-height: 0.2rem;
}

.p-detail-left div {
  color: #606060;
  margin-top: 0.05rem;
}

.p-detail-right p {
  color: #9b9b9b;
  line-height: 0.2rem;
}

.p-detail-right div {
  margin-top: 0.05rem;
}

.p-detail-right div a {
  color: #6480e7;
  font-size: 0.12rem;
}

.tab-box {
  padding: 0 0.15rem;
}

.tab-div {
  margin-top: 0.15rem;
  display: -webkit-flex;
  display: flex;
  background: -moz-linear-gradient(left, #414040, #525252);
  /* background: -webkit-gradient(linear,0 50%,100% 50%,from(#414040),to(#525252)); */
  /* background: -webkit-linear-gradient(left,#414040,#525252); */
  background: -o-linear-gradient(left, #414040, #525252);
  background: linear-gradient(left, #414040, #525252);
  border-top-left-radius: 0.1rem;
  -webkit-border-top-left-radius: 0.1rem;
  border-top-right-radius: 0.1rem;
  -webkit-border-top-right-radius: 0.1rem;
  overflow: hidden;
}

.tab-item {
  flex: 1;
  box-sizing: border-box;
  text-align: center;
  color: #909090;
  cursor: pointer;
}

.tab-item p {
  font-size: 0.15rem;
  padding: 0.2rem 0;
  color: #edbf96;
}

.p-tab-img {
  font-size: 0;
}

.p-tab-img > div {
  margin-bottom: 0.05rem;
}

.p-tab-img img {
  width: 0.75rem;
  height: 0.75rem;
  margin: 0 0.03rem;
  object-fit: cover;
}

.no-line {
  line-height: 0;
}

.tab-info-item {
  padding: 0.15rem 0;
  border-bottom: 0.01rem solid #eee;
  display: flex;
}

.tab-info-item:last-child {
  border: none;
  padding-bottom: 0;
}

.label {
  flex: none;
  width: 1rem;
  float: left;
  height: 0.25rem;
  line-height: 0.25rem;
  font-size: 0.13rem;
}

.img-container {
  .label-img {
    width: 1rem;
    height: 1rem;
    object-fit: contain;
    margin-left: 0.13rem;
  }
}

.label-img {
  width: 1.5rem;
  height: 1.5rem;
  object-fit: contain;
}

.label-video {
  width: 235px;
  height: 1.5rem;
}

.label-info {
  // padding-left: 1rem;
  line-height: 0.25rem;
  min-height: 0.25rem;
  // text-align: right;
  font-size: 0.13rem;
}
/deep/.rich-container {
  img {
    width: 100%;
    object-fit: contain;
  }
  video {
    width: 100%;
  }
  ul,
  li,
  ol,
  li,
  p,
  div {
    width: 100% !important;
    box-sizing: border-box; /* 同样推荐添加 */
  }
}
.rich-container * {
  width: 100% !important;
  /* 这里的 * 表示选择所有后代元素 */
  box-sizing: border-box; /* 同样推荐添加 */
}
.tab-info h1 {
  /* font-size:.17rem;
    padding-left:.1rem;
    line-height:.2rem;
    border-left:0.03rem solid #102A8C; */

  font-size: 0.16rem;
  line-height: 0.2rem;
  margin-bottom: 0.1rem;
}

.tab-info h2 {
  font-size: 0.16rem;
  line-height: 0.2rem;
}

.creat-div {
  padding-top: 0.25rem;
}

.creat-div > div {
  padding-left: 0.9rem;
  line-height: 0.18rem;
  background: url("https://iiot-prod.fxs100.cn/iiot/traceTemplate/1/images/line.png") no-repeat;
  position: relative;
  padding-bottom: 0.2rem;
}

.crice {
  position: absolute;
  left: -0.05rem;
  top: -0.04rem;
  font-size: 0.13rem;
}

.crice span {
  width: 0.12rem;
  height: 0.12rem;
  background: url("https://iiot-prod.fxs100.cn/iiot/traceTemplate/1/images/crice.png") no-repeat;
  background-size: 100% 100%;
  display: inline-block;
  vertical-align: -0.02rem;
  margin-right: 0.05rem;
}

.creat-div > div:last-child {
  background: none;
  padding-bottom: 0;
}

#second-1 > div {
  background: #fff;
  padding: 0.15rem;
  box-sizing: border-box;
  margin-bottom: 0.1rem;
}

#second-1 > div:last-child {
  margin: 0;
}

.line-img img {
  width: 0.8rem;
  height: 0.8rem;
  object-fit: cover;
  background: #333;
}

.line-img {
  width: 0.8rem;
  height: 0.8rem;
  font-size: 0;
  line-height: 0;
  display: inline-block;
}

.detail-box,
.label-detail {
  padding: 0.15rem;
  background: #fff;
}

.detail-info {
  padding: 0.1rem;
  background: rgba(9, 27, 99, 0.05);
  font-size: 0.12rem;
  line-height: 0.2rem;
}

.detail-info div {
  color: #8d8d8d;
}

.no-bg {
  background: transparent;
}

.img-item {
  float: left;
  width: 1rem;
  height: 1rem;
  box-sizing: border-box;
  padding: 0.05rem;
  font-size: 0;
}

.img-item img {
  width: 100%;
  height: 100%;
}

.translate-item {
  padding-bottom: 0.1rem;
  border-bottom: 0.01rem solid #eee;
  margin-bottom: 0.2rem;
}

.translate-item:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border: none;
}

.play {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0rem;
  left: 0rem;
  right: 0rem;
  bottom: 0rem;
  background: rgba(0, 0, 0, 0.3);
}

.play img {
  width: 0.25rem;
  height: 0.25rem;
  cursor: pointer;
}

.video-show {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #333;
  top: 0;
  left: 0;
  font-size: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
}

.video-show video {
  width: 100%;
  height: 2.5rem;
  background: #222;
}

.video-close {
  text-align: center;
  position: absolute;
  left: 0.1rem;
  top: 0.1rem;
  padding: 0.05rem;
  background: #333;
}

.video-close img {
  width: 0.2rem;
}

.no-line {
  line-height: 0;
}

.img-list {
  padding: 0.05rem 0;
}

.no-border .play {
  top: 0;
  left: 0.03rem;
  bottom: 0;
  right: 0.03rem;
}

.no-border .play img {
  width: 0.3rem;
  height: 0.3rem;
}

.no-data {
  text-align: center;
  margin: 0.5rem 0;
}

.no-data img {
  width: 0.4rem;
}

.no-data p {
  margin-top: 0.2rem;
  font-size: 0.14rem;
  color: #4c90ca;
}

.label-width .label {
  width: 1.2rem;
}

.label-width .label-info {
  padding-left: 1.2rem;
}

.video-show > div {
  width: 100%;
}

.disi {
  display: inline-block;
}

.no-border .play {
  top: 0;
  left: 0.03rem;
  bottom: 0;
  right: 0.03rem;
}

.no-border .play img {
  width: 0.3rem;
  height: 0.3rem;
}

.no-data {
  text-align: center;
  margin: 0.5rem 0;
}

.no-data img {
  width: 0.4rem;
}

.no-data p {
  margin-top: 0.2rem;
  font-size: 0.14rem;
  color: #4c90ca;
}

.imgprview {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  height: 100%;
  width: 100%;
}

.mb15 {
  margin-bottom: 0.15rem;
}

.line-p {
  font-size: 0.13rem;
}

.line-con {
  margin-bottom: 0.1rem;
  text-align: right;
  position: relative;
  top: -0.04rem;
}

.line-con:last-child {
  margin-bottom: 0;
}

.times-box {
  margin: 0.3rem;
  box-sizing: border-box;
  padding-top: 0.3rem;
}

.times-box > div {
  padding-left: 0.3rem;
  padding-bottom: 0.4rem;
  position: relative;
  border-left: 0.01rem dashed #000;
  font-size: 0.14rem;
}

.times-box > div:last-child {
  border: none;
  padding-bottom: 0;
}

.times-box > div b {
  display: block;
  width: 0.32rem;
  height: 0.32rem;
  line-height: 0.34rem;
  text-align: center;
  color: #fff;
  border-radius: 10rem;
  background: #5e7ff5;
  font-size: 0.12rem;
  position: absolute;
  left: -0.16rem;
  top: -0.1rem;
}

.scan-tab {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  background: #f7f7f7;
  padding: 0.1rem;
  box-sizing: border-box;
  color: #999;
  font-size: 0.1rem;
  z-index: 10;
}

.scan-tab span:last-child {
  padding-left: 0.1rem;
}

.detail-box > span {
  display: block;
  width: 0.03rem;
  height: 0.45rem;
  background: #102a8c;
  left: 0;
  top: 0;
  position: absolute;
}

.detail-box {
  margin-bottom: 0.1rem;
}

.detail-box:last-child {
  margin-bottom: 0;
}

.fixed-bar {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  font-size: 0;
  z-index: 9;
  text-align: right;
  padding: 0.05rem 0;
}

.fixed-bar > .fixed-item {
  width: 0.62rem;
  display: inline-block;
  vertical-align: bottom;
}

.fixed-bar > .fixed-item a {
  width: 0.5rem;
  height: 0.5rem;
  background: #0091ff;
  color: #fff;
  margin: 0 auto;
  border-radius: 1rem;
  display: flex;
  /*Flex*/
  display: -webkit-flex;
  /* Safari */
  align-items: center;
  justify-content: center;
  padding: 0 0.1rem;
  box-sizing: border-box;
  text-align: center;
  box-shadow: 0 0rem 0.08rem rgba(0, 0, 0, 0.2);
}

.fixed-bar > .fixed-item a p {
  font-size: 0.11rem;
  line-height: 0.18rem;
}

.fixed-bar > .fixed-item:nth-child(2) a {
  background: #f7b500;
}

.fixed-bar > .fixed-item:nth-child(3) a {
  background: #112c92;
}

.fixed-bar > .fixed-item:nth-child(4) a {
  background: #c6ba00;
}

.fixed-bar > .fixed-item:nth-child(5) a {
  background: #ff7800;
}

.ptb8 {
  padding-bottom: 0.7rem;
}

.show-text {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 10;
  display: none;
}

.show-text-con {
  width: 3rem;
  position: absolute;
  top: 50%;
  margin-top: -1rem;
  left: 50%;
  margin-left: -1.5rem;
  background: #fff;
  border-radius: 0.05rem;
}

.show-title {
  padding: 0.13rem 0.1rem;
  border-bottom: 0.01rem solid #eee;
  font-size: 0.16rem;
}

.show-con {
  padding: 0.2rem 0.1rem;
  font-size: 0.14rem;
}

.show-close {
  text-align: Center;
  border-top: 0.01rem solid #eee;
  padding: 0.13rem 0.1rem;
  font-size: 0.12rem;
}

.tab-info b {
  display: block;
  width: 0.03rem;
  height: 0.45rem;
  background: #102a8c;
  left: 0;
  top: 0;
  position: absolute;
}

#second-1 b,
#second-3 b {
  margin-bottom: 0.2rem;
}

.p-info-btn a {
  color: #fff;
}

.pt15 {
  padding-top: 0.15rem;
}

.liuhai-icon {
  width: 2.5rem;
  margin: auto;
  font-size: 0;
  position: relative;
  z-index: 100;
}

.liuhai-icon img {
  width: 100%;
}

.tab-info {
  padding: 0 0.15rem;
}

.tab-item span {
  display: none;
}

.tab-item.on p {
  color: #fff;
}

.tab-item.on span {
  position: absolute;
  display: inline-block;
  width: 12px;
  height: 7px;
  background: url("https://cctv-gw.kexinid.com/trace/static/images/apply.png") no-repeat;
  background-size: 100% 100%;
  bottom: 0;
  left: 50%;
  margin-left: -6px;
}

.tab-item {
  position: relative;
}

.web-text {
  position: absolute;
  left: 0.3rem;
  top: 0.7rem;
  z-index: 10;
}

.wrap-header.no-style {
  height: 2.6rem;
}

.wrap-header.no-style .web-text {
  top: 0.2rem;
}

.wrap-header.no-style .web-status {
  top: -1.2rem;
}

.price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.16rem;
  height: 0.6rem;
  box-shadow: 0px -2px 12px rgba(100, 101, 102, 0.12);
  border-radius: 0px 0px 8px 8px;
}

.price span:first-child {
  font-weight: bold;
  color: #ff4f4c;
  font-size: 0.2rem;
}

.price span:last-child {
  text-align: center;
  line-height: 44px;
  width: 1.5rem;
  height: 0.4rem;
  background: linear-gradient(270deg, #f0142d 0%, #ed6a0c 100%);
  color: #ffffff;
  border-radius: 0.4rem;
}

.message_board {
  background: none !important;
  color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 0.16rem;
  height: 3.6rem;
  padding: 0.15rem 0 !important;
}

.message_input {
  // margin-top: 12px;
}

.mask {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.18rem;
  width: 120px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.65);
  color: #fff;
  z-index: 999;
}

.submit {
  width: 100%;
  height: 0.4rem;
  background: #c89469;
  font-size: 0.18rem;
  margin-top: 12px;
  border-radius: 4px;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.message_board span {
  margin-bottom: 27px;
}

.label-video iframe {
  width: 235px;
  height: 1.5rem;
}

.el-form-item {
  margin-bottom: 22px !important;
}

.ant_img_box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ant_img_box .top_ant {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.12rem;
}

.ant_img_box .top_ant img {
  margin-right: 5px;
}

.ant_img_box .text_desc {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.12rem;
  margin-bottom: 0.3rem;
  color: #4e4c4c;
  width: 66%;
  text-align: center;
}

.ant_img_box .qrcode_img_box {
  width: 1.7rem;
  height: 1.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-bottom: 6rem;
  background: url("https://assets-service.obs.cn-south-1.myhuaweicloud.com/test/mp_82382310-c251-11ed-a857-e14019a61c1e/8d4b5768-fbf9-4409-bc7a-49ac1461309d.png");
  background-size: cover;
}

.ant_img_box .qrcode_img_box .qrcode_img {
  width: 1.5rem;
  height: 1.5rem;
  text-align: center;
}

.copy-link {
  font-size: 0.12rem;
  color: #4464eb;
  margin-top: 0.13rem;
  line-height: 0.24rem;
}

.ant_img_box .qrcode_desc {
  color: #a5a5a5;
  margin-top: 0.13rem;
  font-size: 0.12rem;
  margin-bottom: 0.1rem;
}

.ant_img_box .close_btn {
  width: 0.6rem;
  height: 0.28rem;
  /* background: #3c65f5; */
  border-radius: 0.02rem;
  font-size: 0.12rem;
  color: #3c65f5;
  text-align: center;
  cursor: pointer;
  line-height: 0.28rem;
}
/deep/ .el-dialog__body {
  padding: 10px;
}

.ant_img_box .top_ant div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.ant_img_box img {
  width: 0.24rem;
  height: 0.24rem;
}

.ant_img_box .title {
  font-size: 0.18rem;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #303133;
}

.ant_img_box .desc {
  font-size: 0.12rem;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #303133;
}

.ant_img_box .border_qrcode {
  border-bottom: 2px solid #fafafa;
  height: 1px;
  width: 80%;
}
</style>
