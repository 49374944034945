<template>
  <p>404 page not found</p>
</template>

<script>
export default {
};
</script>

<style>

</style>
