<template>
  <div class="main">
    <div class="login">
      <div class="logonMain">
        <div class="left">
          <img class="logo" src="../images/client_logo.png">
          <img class="company" src="../images/login.png">
          <div class="tip">工业互联网标识应用中台</div>
        </div>
        <div class="right" v-if="handle == 'login'">
          <div class="login-box">
            <div class="title">欢迎登录工网通应用管理系统</div>
            <div class="tabs">
              <div
                :class="{ active: loginWay == 'password' }"
                @click="
                  loginWay = 'password';
                  code = '';
                "
              >
                账号密码登录
              </div>
              <div
                :class="{ active: loginWay == 'code' }"
                @click="
                  loginWay = 'code';
                  password = '';
                "
              >
                验证码登录
              </div>
            </div>
            <div class="inputStyle">
              <span class="label">手机号</span>
              <el-input
                prefix-icon="iconyonghuming iconfont"
                placeholder="请输入手机号"
                border="none"
                v-model.trim="phone"
              />
            </div>
            <div class="inputStyle" v-if="loginWay == 'password'">
              <span class="label">密码</span>
              <el-input
                prefix-icon="iconmima iconfont"
                placeholder="请输入密码"
                v-model.trim="password"
                type="password"
                show-password
                @keyup.enter.native="login"
              />
            </div>
            <div class="inputStyle" v-if="loginWay == 'code'">
              <span class="label">验证码</span>
              <el-input
                prefix-icon="iconmima iconfont"
                placeholder="请输入密码"
                v-model.number="code"
                @keyup.enter.native="login"
              />
              <span class="code" @click="getCode">{{ content }}</span>
            </div>
            <div class="inputStyle" style="border-bottom: none">
              <el-checkbox v-model="keep_login">记住账号密码</el-checkbox>
            </div>
            <el-button type="primary" class="loginBtn" @click="login"> 登录 </el-button>
            <div class="forgot-password" v-if="loginWay == 'password'" @click="showFindPassword">
              忘记密码
            </div>
          </div>
        </div>

        <div class="right" v-if="handle == 'findPassword'">
          <div class="login-box reset-password">
            <div class="title">重置密码</div>
            <div class="inputStyle">
              <span class="label">手机号</span>
              <el-input
                prefix-icon="iconyonghuming iconfont"
                placeholder="请输入手机号"
                border="none"
                v-model.trim="phone"
              />
            </div>
            <div class="inputStyle">
              <span class="label">验证码</span>
              <el-input
                prefix-icon="iconmima iconfont"
                placeholder="请输入密码"
                v-model.number="code"
              />
              <span class="code" @click="getCode">{{ content }}</span>
            </div>
            <div class="inputStyle">
              <span class="label">新密码</span>
              <el-input
                prefix-icon="iconmima iconfont"
                placeholder="请输入密码"
                v-model.trim="password"
                type="password"
                show-password
              />
            </div>
            <div class="inputStyle">
              <span class="label">确认密码</span>
              <el-input
                prefix-icon="iconmima iconfont"
                placeholder="请输入密码"
                v-model.trim="confirm_password"
                type="password"
                show-password
                @keyup.enter.native="resetPassword"
              />
            </div>
            <el-button type="primary" class="loginBtn" @click="resetPassword"> 设置 </el-button>
            <div class="forgot-password" @click="handle = 'login'">返回登录></div>
          </div>
        </div>
      </div>
    </div>
    <div class="record">
      <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备2021024496号</a>
    </div>
  </div>
</template>

<script>
import md5 from "js-md5";
const Base64 = require("js-base64").Base64;
import { userInfoApi } from "../services/api";
import { phone } from "../utils/regExp";
let _regx = phone;
export default {
  data() {
    return {
      phone: "",
      password: "",
      confirm_password: "",
      code: "",
      keep_login: false,
      loginWay: "password", // password 密码   code 验证码
      handle: "login", // login 登录  findPassword 找回密码
      canSend: false,
      content: "获取验证码",
      sendTime: 60,
      redirect_url: this.$route.query.redirect_url || "",
      company_phone: this.$route.query.phone || "",
      params: {
        company_id: this.$route.query.id,
        company_mpkey: this.$route.query.mpkey,
        session: this.$route.query.session
      }
    };
  },
  mounted() {
    if (this.params.company_id && (this.params.company_mpkey || this.company_phone)) {
      this.autoLogin();
    }
    let username = localStorage.getItem("userId");
    if (username) {
      this.phone = localStorage.getItem("userId");
      this.password = Base64.decode(localStorage.getItem("password")); // base64解密
      this.keep_login = true;
    }
  },
  methods: {
    async autoLogin() {
      console.log("this.params", this.params);
      if (this.redirect_url) {
        let { data } = await userInfoApi.customerOauth({
          merchant_id: this.params.company_id,
          ms_session: this.params.session,
          phone: this.company_phone
        });
        this.$store.commit("login", data);
        this.$store.dispatch("saveUserSession");
        this.$router.push({ path: this.redirect_url });
      } else {
        let { data } = await userInfoApi.oauth(this.params);
        this.$store.commit("login", data);
        this.$store.dispatch("saveUserSession");
        this.$router.push({ path: "/home" });
      }
    },
    async login() {
      if (!this.phone || !_regx.test(this.phone)) {
        this.$message({
          message: "请输入正确的手机号",
          type: "warning"
        });
        return false;
      } else if (this.loginWay == "password" && !this.password) {
        this.$message({
          message: "请输入密码",
          type: "warning"
        });
        return false;
      } else if (this.loginWay == "code" && !this.code) {
        this.$message({
          message: "请输入短信验证码",
          type: "warning"
        });
        return false;
      }
      let _password = this.password ? md5(`${this.password}industrialinternet_customer_key`) : "";
      this.code = this.loginWay == "password" ? "" : this.code;
      this._password = this.loginWay != "password" ? "" : this._password;

      await this.$store.dispatch("login", {
        phone: this.phone,
        password: _password,
        code: this.code,
        type: this.loginWay == "password" ? 1 : 0,
        log_browser_info: navigator.userAgent.toLowerCase()
      });
      if (this.keep_login) {
        let password = Base64.encode(this.password); // base64加密
        localStorage.setItem("userId", this.phone);
        localStorage.setItem("password", password);
      }
    },
    async getCode() {
      if (this.canSend) return false;
      if (!this.phone || !_regx.test(this.phone)) {
        this.$message({
          message: "请输入正确的手机号",
          type: "warning"
        });
        return false;
      }
      await userInfoApi.getCode({ phone: this.phone });
      this.canSend = true;
      this.content = `${this.sendTime}s后重新发送`;
      let timer = setInterval(() => {
        this.sendTime--;
        this.content = `${this.sendTime}s后重新发送`;
        if (this.sendTime < 0) {
          clearInterval(timer);
          this.content = "重新发送验证码";
          this.sendTime = 60;
          this.canSend = false; // 这里重新开启
        }
      }, 1000);
    },
    async resetPassword() {
      if (!this.phone || !_regx.test(this.phone)) {
        this.$message({
          message: "请输入正确的手机号",
          type: "warning"
        });
        return false;
      } else if (!this.code) {
        this.$message({
          message: "请输入短信验证码",
          type: "warning"
        });
        return false;
      } else if (!this.password) {
        this.$message({
          message: "请输入新密码",
          type: "warning"
        });
        return false;
      } else if (!this.confirm_password) {
        this.$message({
          message: "请输入确认密码",
          type: "warning"
        });
        return false;
      }
      await userInfoApi.resetPassword({
        phone: this.phone,
        code: this.code,
        password: this.password,
        confirm_password: this.confirm_password
      });
      this.$message({
        message: "设置成功",
        type: "success"
      });
      this.handle = "login";
    },
    showFindPassword() {
      this.handle = "findPassword";
      this.content = "获取验证码";
      this.canSend = false;
      this.sendTime = 60;
    }
  }
};
</script>

<style scoped lang="less">
div,
span,
i,
input {
  box-sizing: border-box;
}
.main {
  position: relative;
  background-color: #0a4b8d;
  min-height: 800px;
  height: 100vh;
}
.record {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 12px;
  font-size: 12px;
  text-align: center;
}
a {
  text-decoration: none;
  color: #999;
}
.head_img {
  margin: 50px 0 0 70px;
}
.login {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding-top: calc((100vh - 682px) / 2);
}
.el-input--prefix .el-input__inner {
  border: none;
}
.codeTips {
  color: var(--theme-color);
  font-size: 12px;
  margin-left: 31px;
  display: block;
}

.logonMain {
  width: 1001px;
  height: 682px;
  border-radius: 8px;
  display: flex;
  .left {
    width: 0;
    flex-grow: 1;
    background: radial-gradient(circle, #198dd1 0%, #0f5b9d 100%);
    height: 100%;
    .logo {
      width: 341px;
      object-fit: cover;
      margin-top: 69px;
      margin-left: 69px;
    }
    .company {
      width: 410px;
      margin-top: 48px;
      object-fit: cover;
      margin-left: 76px;
    }
    .tip {
      text-align: center;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
    }
  }
  .right {
    width: 442px;
    height: 100%;
    background-color: #ffffff;
    .login-box {
      width: 287px;
      margin: 159px auto 0;
      .title {
        font-size: 18px;
        font-family: MicrosoftYaHei;
        line-height: 19px;
        color: #1285d6;
        text-align: center;
        margin-bottom: 62px;
      }
      .tabs {
        display: flex;
        justify-content: center;
        margin-bottom: 47px;
        font-size: 14px;
        color: #7b7e8c;
        div {
          cursor: pointer;
        }
        div:first-child {
          margin-right: 19px;
        }
        .active {
          color: #011539;
          position: relative;
        }
        .active::after {
          content: "";
          position: absolute;
          left: 45%;
          bottom: -6px;
          width: 20px;
          height: 2px;
          background: #011539;
          border-radius: 1px;
        }
      }
    }
    .reset-password {
      .title {
        font-size: 24px;
        color: #011539;
        text-align: left;
      }
      .label {
        width: 75px;
      }
    }
  }
}
.loginBtn {
  width: 100%;
  background-color: var(--theme-color);
  text-align: center;
  color: #ffffff;
  border-radius: 2px;
  margin-top: 21px;
  height: 40px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
.inputStyle {
  margin: 0 auto;
  margin-top: 17px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  font-size: 14px;
  color: #2d2d2d;
  .label {
    width: 50px;
  }
  .code {
    color: var(--theme-color);
    font-size: 14px;
    width: 100px;
    text-align: right;
    cursor: pointer;
  }
}
/deep/ .el-input {
  width: 0;
  flex-grow: 1;
}
.forgot-password {
  font-size: 14px;
  font-family: MicrosoftYaHei;
  line-height: 17px;
  color: var(--theme-color);
  text-align: right;
  margin-top: 15px;
  cursor: pointer;
  width: 80px;
  float: right;
}

.codeInput {
  margin: 0 auto;
  overflow: hidden;
  margin-top: 24px;
}
.codeMian {
  width: 162px;
  float: left;
}
.codeInput .codeImg {
  width: 94px;
  float: right;
  height: 40px;
}
.bottomTitle {
  text-align: right;
  padding: 0;
  margin: 0;
  color: #606266;
  font-size: 12px;
  margin: 0 auto;
  margin-top: 24px;
  width: 297px;
}
.logonMain /deep/ .el-input__inner {
  border: none;
  height: 40px;
  padding: 0 6px;
  // background-color: #F7F7F7;
}
/deep/ .el-input__icon {
  font-size: 20px;
}
/deep/ .el-input .el-input__clear {
  font-size: 20px;
}
</style>
